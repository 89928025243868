import axios from "axios";
import {makeAutoObservable} from "mobx";
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ROWS_PER_PAGE} from "../default_values";
import React from "react";
import _get from "lodash/get";
import {API, AUTH_HEADERS} from "../../config";
import {REQUEST_STATE} from "../../consts";
import dayjs from "dayjs";
import Status from "./status";

export default class Data {
    requests = [];
    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    activePage = DEFAULT_ACTIVE_PAGE;
    loading = false;
    total = 10;
    toastStore = null;
    userStore = null;
    orderField = "createdAt";
    orderBy = "asc";
    filters = {
        parent_name: "",
        parent_surname: "",
        parent_patronymic: "",
        child_name: "",
        child_surname: "",
        child_patronymic: "",
        created: false,
        merged: false,
        closed: false,
        denied: false,
    };

    constructor(toastStore, userStore) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = userStore;
    }

    /**
     * Изменение сортировки
     */
    onChangeSortingType = (event, property) => {
        const isAsc = this.orderField === property && this.orderBy === 'asc';
        this.orderBy = isAsc ? 'desc' : 'asc';
        this.orderField = property;
        this.getRequestList();
    };

    /**
     * Список колонок для таблицы
     */
    get columns() {
        return [
            {
                label: "Родительский аккаунт",
                style: {minWidth: "490px", width: "490px"},
                numeric: false,
                field: "userAccountToFio"
            },
            {
                label: "Дочерний аккаунт",
                style: {minWidth: "490px", width: "490px"},
                numeric: false,
                field: "userAccountFromFio"
            },
            {
                label: "Дата заявки",
                style: {minWidth: "200px"},
                numeric: false,
                field: "createdAtString",
                sorted: true,
                sorted_field: "createdAt",
            },
            {
                label: "Статус заявки",
                style: {minWidth: "220px"},
                numeric: false,
                field: "status"
            }
        ]
    }

    /**
     * Конвертация статуса в текст 
     */
    convertStatusToText = (state, fromState, toState) => {
        switch (state) {
            case "CREATED":
                if (fromState === "DECLINED")
                    return "Отклонено пользователем";
                if (toState === "CANCELLED")
                    return "Отозвано пользователем";
                return "На рассмотрении";
            case "MERGED":
                return "Одобрена";
            case "CLOSED":
                return "Отказано";
            case "DENIED":
                return "Заблокирована";
            case "UNMERGED":
                return "Слияние отменено";
            default: 
                return "Неизвестный статус";
        }
    };


    /**
     * Конвертация статуса в текст 
     */
    convertStatusToColor = (state, fromState, toState) => {
        switch (state) {
            case "CREATED":
                if (fromState === "DECLINED")
                    return "#FF4D4F";
                if (toState === "CANCELLED")
                    return "#FF4D4F";
                return "#D9D9D9";
            case "MERGED":
                return "#11DD7B";
            case "CLOSED":
                return "#FF4D4F";
            case "DENIED":
                return "#FFF566";
            default:
                return "#FF4D4F";
        }
    };


    /**
     * Получение списка заявок с бекенда
     */
    getRequestList = async () => {
        this.loading = true;
        const data = {
            offset: (this.activePage-1) * this.rowsPerPage,
            limit: this.rowsPerPage,
            filters: [],
            sortBy: [{
                field: this.orderField,
                direction: this.orderBy.toUpperCase()
            }]
        };
        //region filters
        if (this.filters.parent_name) {
            data.filters.push({
                field: "userAccountTo.name",
                regExp: `%${this.filters.parent_name}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.parent_surname) {
            data.filters.push({
                field: "userAccountTo.surname",
                regExp: `%${this.filters.parent_surname}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.parent_patronymic) {
            data.filters.push({
                field: "userAccountTo.patronymic",
                regExp: `%${this.filters.parent_patronymic}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.child_name) {
            data.filters.push({
                field: "userAccountFrom.name",
                regExp: `%${this.filters.child_name}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.child_surname) {
            data.filters.push({
                field: "userAccountFrom.surname",
                regExp: `%${this.filters.child_surname}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.child_patronymic) {
            data.filters.push({
                field: "userAccountFrom.patronymic",
                regExp: `%${this.filters.child_patronymic}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.created || this.filters.merged || this.filters.closed || this.filters.denied) {
            data.filters.push({
                field: "state",
                in: [
                    this.filters.created ? REQUEST_STATE.CREATED : "", 
                    this.filters.merged ? REQUEST_STATE.MERGED : "", 
                    this.filters.closed ? REQUEST_STATE.CLOSED : "", 
                    this.filters.denied ? REQUEST_STATE.DENIED : ""
                ].filter(i => i)
            });
        }
        //endregion
        let response;
        try {
            response = await axios.post(`${API}/admin/user-account-merge-requests/filter`, data, {headers: AUTH_HEADERS()});
        }
        catch (e) {
            if (e.response.status === 401) {
                this.userStore.logout();
                return;
            }
        }
        this.requests = response.data.data.map(i =>  {
            const userAccountToFio = `${_get(i, "userAccountTo.surname", "")} ${_get(i, "userAccountTo.name", "")} ${i.userAccountTo?.patronymic || ""}`;
            let userAccountFromFio = `${_get(i, "userAccountFrom.surname", "")} ${_get(i, "userAccountFrom.name", "")} ${i.userAccountFrom?.patronymic || ""}`;
            if (_get(i, "userAccountFrom.state") === "merged") {
                userAccountFromFio = `${_get(i, "userAccountFrom.userAccountMerged.surname", "")} ${_get(i, "userAccountFrom.userAccountMerged.name", "")} ${i.userAccountFrom.userAccountMerged.patronymic || ""}`;
            }
            console.log("i", i);
            return {
                ...i,
                userAccountToFio,
                userAccountFromFio,
                createdAtString: dayjs(i.createdAt).format("DD.MM.YYYY HH:mm"),
                status: <Status color={this.convertStatusToColor(i.state, i.fromState, i.toState)}
                                text={this.convertStatusToText(i.state, i.fromState, i.toState)}/>
            }
        });
        this.total = response.data.total;
        this.loading = false;
    };

    /**
     * изменение страницы
     */
    onPageChange = page => {
        this.activePage = page;
        this.getRequestList();
    };

    /**
     * Изменение количества на странице
     */
    onRowsPerPageChange = count => {
        this.rowsPerPage = count;
        this.onPageChange(DEFAULT_ACTIVE_PAGE);
    };

    /**
     * Функция применения фильтров
     */
    changeFilters = filters => {
        this.filters = filters;
        this.onPageChange(DEFAULT_ACTIVE_PAGE);
    };
}
