import AuthPage from "./components/AuthPage";
import React from "react";
import { Navigate } from 'react-router-dom';
import PortalsPage from "./components/PortalsPage";
import UsersPage from "./components/UsersPage";
import OrganizationsPage from "./components/OrganizationsPage";
import PlanetIcon from "./icons/PlanetIcon";
import UserIcon from "./icons/UserIcon";
import BookIcon from "./icons/BookIcon";
import InformationIcon from "./icons/InformationIcon";
import FailedTriesPage from "./components/FailedTriesPage";
import RequestsIcon from "./icons/RequestsIcon";
import RequestsPage from "./components/RequestsPage";

export const routesUnAuth = {
    main: {
        path: "/",
        element: <AuthPage/>
    },
    redirect: {
        path: "*",
        element: <Navigate to="/" replace />
    },
};

const routes = {
    main: {
        path: "/",
        element: <PortalsPage/>,
        title: "Управление порталами",
        icon: PlanetIcon
    },
    users: {
        path: "/users",
        element: <UsersPage/>,
        title: "Пользователи",
        icon: UserIcon
    },
    requests: {
        path: "/requests",
        element: <RequestsPage/>,
        title: "Слияние аккаунтов",
        icon: RequestsIcon
    },
    organizations: {
        path: "/organizations",
        element: <OrganizationsPage/>,
        title: "Реестр организаций",
        icon: BookIcon
    },
    failedTries: {
        path: "/failed_tries",
        element: <FailedTriesPage/>,
        title: "Реестр неудачных попыток авторизации",
        icon: InformationIcon
    },
};

export default routes;
