import {makeAutoObservable} from "mobx"

/**
 * Хранилище всего приложение
 */
export default class AppStore {
    pageTitle = "";

    constructor() {
        makeAutoObservable(this);
    }

    setPageTitle = title => {
        this.pageTitle = title;
        document.title = `MO SSO - ${title}`;
    };
}
