import {makeAutoObservable} from "mobx";
import axios from "axios";
import {API, AUTH_HEADERS} from "../../../config";

export default class Data {
    portal = {};
    scopes = [];
    closeEvent;
    toastStore;
    userStore;
    updateListEvent;
    portalId;
    newUrl = "";
    newScope = "";

    constructor(portalId, closeEvent, toastStore, userStore, updateList) {
        makeAutoObservable(this);
        this.portalId = portalId;
        this.closeEvent = closeEvent;
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.updateListEvent = updateList;
    }

    /**
     * Изменение параметров портала
     */
    onChangeParameter = (param, value) => {
        this.portal[param] = value;
    };

    /**
     * Запрос данных
     */
    loadPortal = () => {
        if (this.portalId !== -1) {
            axios.get(`${API}/admin/clients/${this.portalId}`, {headers: AUTH_HEADERS()})
                .then(r => {
                    this.portal = r.data;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.userStore.logout();
                        return;
                    }
                    this.toastStore.showError("Ошибка получения данных о портале");
                    this.closeEvent();
                });
        }
    };

    /**
     * Запрос списка Scopes
     */
    loadScopeList = () => {
        axios.get(`${API}/admin/clients/scopes`, {headers: AUTH_HEADERS()})
            .then(r => {
                this.scopes = r.data;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка получения данных о портале");
                this.closeEvent();
            });
    };

    /**
     * Изменение ввода нового URL
     */
    onChangeInputNewUrl = e => {
        if (e) {
            this.newUrl = e.target.value;
        }
        if (this.newUrl.trim().length) {
            const arrays = this.newUrl.trimStart().split(" ");
            if ((e && arrays.length === 2) || !e) {
                this.addUrl(arrays[0]);
                this.newUrl = "";
            }
        }
    };

    /**
     * Изменение ввода нового URL
     */
    onChangeInputNewScope = e => {
        if (e) {
            this.newScope = e.target.value;
        }
        if (this.newScope.trim().length) {
            const arrays = this.newScope.trimStart().split(" ");
            if ((e && arrays.length === 2) || !e) {
                this.addScope(arrays[0]);
                this.newScope = "";
            }
        }
    };

    /**
     * Добавление нового URL
     */
    addUrl = url => {
        if (!this.portal.redirectUris) {
            this.portal.redirectUris = [url];
        }
        else {
            this.portal.redirectUris.push(url);
        }
    };

    /**
     * Удаление url
     */
    removeUrl = url => {
        this.portal.redirectUris = this.portal.redirectUris.filter(i => i !== url);
    };

    /**
     * Удаление портала
     */
    deletePortal = () => {
        axios.delete(`${API}/admin/clients/${this.portalId}`, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Портал удален");
                this.updateListEvent();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка удаления портала");
            });
    };

    /**
     * Создание нового портала
     */
    createPortal = () => {
        if (this.portal.accessTokenLifespan) {
            if (parseInt(this.portal.accessTokenLifespan) < 0) {
                this.toastStore.showError("Время жизни токена не может быть отрицательным");
                return;
            }
        }
        if (this.portalId === -1) {
            //Создание
            axios.post(`${API}/admin/clients`, this.portal, {headers: AUTH_HEADERS()})
                .then(() => {
                    this.toastStore.showSuccess("Портал создан");
                    this.updateListEvent();
                    this.closeEvent();
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.userStore.logout();
                        return;
                    }
                    this.toastStore.showError("Ошибка создания портала");
                });
        }
        else {
            //Редактирование
            axios.patch(`${API}/admin/clients/${this.portalId}`, this.portal, {headers: AUTH_HEADERS()})
                .then(() => {
                    this.toastStore.showSuccess("Изменения сохранены");
                    this.updateListEvent();
                    this.closeEvent();
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.userStore.logout();
                        return;
                    }
                    this.toastStore.showError("Ошибка сохранения изменений");
                });
        }
    };

    /**
     * Изменение ввода нового Scope
     */
    onChangeSelectNewScope = e => {
        this.addScope(e.target.value);
    };

    /**
     * Добавление нового Scope
     */
    addScope = scope => {
        if (!this.portal.defaultClientScopes) {
            this.portal.defaultClientScopes = [scope];
        }
        else {
            this.portal.defaultClientScopes.push(scope);
        }
    };

    /**
     * Удаление Scope
     */
    removeScope = scope => {
        this.portal.defaultClientScopes = this.portal.defaultClientScopes.filter(i => i !== scope);
    };
}
