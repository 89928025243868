import axios from "axios";
import {makeAutoObservable} from "mobx";
import {API, AUTH_HEADERS} from "../../../config";
import _get from "lodash/get";

export default class Data {
    toastStore = null;
    userStore = null;
    updateList = null;
    closeEvent = null;
    user = {
        login: "",
        name: "",
        surname: "",
        patronymic: "",
        emailAddress: "",
        msisdn: "",
    };

    constructor(toastStore, userStore, updateList, closeEvent) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = toastStore;
        this.updateList = updateList;
        this.closeEvent = closeEvent;
    }

    /**
     * Валидация ввода 
     */
    validateInput = (field, value) => {
        switch (field) {
            case "name":
            case "surname":
            case "patronymic":
                return !!value.match(/[a-zA-Zа-яА-Я]+$/gm);
            case "msisdn":
                return !!value.match(/[+0-9]+$/gm) && value.length <= 12 && value[0] === '+' && (value.length > 1 ? value[1] === '7' : true);
        }
        return true;
    };

    /**
     * Изменение значений в форме
     */
    changeUser = (field, value) => {
        if (!value) {
            this.user[field] = value.replace(/\s/g, '');
        }
        if (this.validateInput(field, value)) {
            this.user[field] = value.replace(/\s/g, '');
        }
    };

    /**
     * Валидация EMAIL
     */
    validateEmail = () => {
        if (!this.user.emailAddress)
            return true;

        return !!String(this.user.emailAddress.replace(/\s/g, ''))
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    /**
     * Валидация телефона
     */
    validatePhone = () => {
        if (!this.user.msisdn)
            return true;

        return !!String(this.user.msisdn.replace(/\s/g, ''))
            .toLowerCase()
            .match(/[+0-9]+$/gm);
    };

    /**
     * Создание пользователя через API
     */
    create = () => {
        if (!this.user.login) {
            this.toastStore.showError("Введите логин");
            return;
        }
        if (!this.user.name) {
            this.toastStore.showError("Введите имя");
            return;
        }
        if (!this.user.surname) {
            this.toastStore.showError("Введите фамилию");
            return;
        }
        if (!this.user.msisdn || !this.user.emailAddress) {
            this.toastStore.showError("Введите телефон и email");
            return;
        }
        if (
            this.user.login.length > 20 ||
            this.user.name.length > 20 ||
            this.user.surname.length > 20 ||
            this.user.patronymic.length > 20
        ) {
            this.toastStore.showError("Поля: ФИО и Логин не могут быть более 20 символов");
            return;
        }
        if (!this.validateEmail()) {
            this.toastStore.showError("Некорректный email");
            return;
        }
        if (!this.validatePhone()) {
            this.toastStore.showError("Некорректный телефон");
            return;
        }

        if (this.user.msisdn)
            this.user.msisdn = this.user.msisdn.substring(1);

        axios.post(`${API}/admin/user-accounts`, this.user, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Пользователь создан");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.user.msisdn = "+" + this.user.msisdn;
                const localizedMessage = _get(error.response, "data.localizedMessage", null);
                const message = _get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    }
}
