import React from "react";

const BookIcon = ({isSelected}) => {
    let fill = "black", fillOpacity = 0.85;
    if (isSelected) {
        fill = "#1890FF";
        fillOpacity = 1;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <g clipPath="url(#clip0_68_8770)">
                <path d="M13.5 1.51752H9.925C9.15781 1.51752 8.40781 1.73783 7.7625 2.15346L7 2.64252L6.2375 2.15346C5.59283 1.73791 4.84199 1.51711 4.075 1.51752H0.5C0.223437 1.51752 0 1.74096 0 2.01752V10.8925C0 11.1691 0.223437 11.3925 0.5 11.3925H4.075C4.84219 11.3925 5.59219 11.6128 6.2375 12.0285L6.93125 12.4753C6.95156 12.4878 6.975 12.4956 6.99844 12.4956C7.02187 12.4956 7.04531 12.4894 7.06563 12.4753L7.75937 12.0285C8.40625 11.6128 9.15781 11.3925 9.925 11.3925H13.5C13.7766 11.3925 14 11.1691 14 10.8925V2.01752C14 1.74096 13.7766 1.51752 13.5 1.51752ZM4.075 10.2675H1.125V2.64252H4.075C4.62812 2.64252 5.16562 2.80033 5.62969 3.09877L6.39219 3.58783L6.5 3.65814V10.8769C5.75625 10.4769 4.925 10.2675 4.075 10.2675ZM12.875 10.2675H9.925C9.075 10.2675 8.24375 10.4769 7.5 10.8769V3.65814L7.60781 3.58783L8.37031 3.09877C8.83438 2.80033 9.37187 2.64252 9.925 2.64252H12.875V10.2675ZM5.20156 4.64252H2.29844C2.2375 4.64252 2.1875 4.69564 2.1875 4.7597V5.46283C2.1875 5.52689 2.2375 5.58002 2.29844 5.58002H5.2C5.26094 5.58002 5.31094 5.52689 5.31094 5.46283V4.7597C5.3125 4.69564 5.2625 4.64252 5.20156 4.64252ZM8.6875 4.7597V5.46283C8.6875 5.52689 8.7375 5.58002 8.79844 5.58002H11.7C11.7609 5.58002 11.8109 5.52689 11.8109 5.46283V4.7597C11.8109 4.69564 11.7609 4.64252 11.7 4.64252H8.79844C8.7375 4.64252 8.6875 4.69564 8.6875 4.7597ZM5.20156 6.83002H2.29844C2.2375 6.83002 2.1875 6.88314 2.1875 6.9472V7.65033C2.1875 7.71439 2.2375 7.76752 2.29844 7.76752H5.2C5.26094 7.76752 5.31094 7.71439 5.31094 7.65033V6.9472C5.3125 6.88314 5.2625 6.83002 5.20156 6.83002ZM11.7016 6.83002H8.79844C8.7375 6.83002 8.6875 6.88314 8.6875 6.9472V7.65033C8.6875 7.71439 8.7375 7.76752 8.79844 7.76752H11.7C11.7609 7.76752 11.8109 7.71439 11.8109 7.65033V6.9472C11.8125 6.88314 11.7625 6.83002 11.7016 6.83002Z" fill={fill} fillOpacity={fillOpacity}/>
            </g>
            <defs>
                <clipPath id="clip0_68_8770">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
};

export default BookIcon;
