import React from "react";

const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M4 3.5H20V5.5H4V3.5Z" fill="#FF4D4F"/>
            <path d="M17 5.5L14 2.5H10L7 5.5H17ZM5 7.5V20.5C5 21.6 5.9 22.5 7 22.5H17C18.1 22.5 19 21.6 19 20.5V7.5H5ZM16 17.09L14.59 18.5L12 15.91L9.41 18.5L8 17.09L10.59 14.5L8 11.91L9.41 10.5L12 13.09L14.59 10.5L16 11.91L13.41 14.5L16 17.09Z" fill="#FF4D4F"/>
        </svg>
    )
};

export default DeleteIcon;
