import {makeAutoObservable} from "mobx";
import routes from "../../routes";

export default class Data {
    navigate;
    userStore;
    username = "";
    password = "";
    loading = false;

    constructor(navigate, userStore) {
        this.navigate = navigate;
        this.userStore = userStore;
        makeAutoObservable(this);
    }

    /**
     * Метод запуска авторизации
     */
    onAuth = async () => {
        this.loading = true;
        const result = await this.userStore.login(this.username, this.password);
        this.loading = false;
        if (result)
            this.navigate(routes.main.path);
    }
}
