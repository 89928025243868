import React from "react";
import Modal from "../Elements/Modal";
import Box from "@mui/material/Box";
import "./index.scss";
import Button from "@mui/material/Button";

/**
 * Прототип диалоговых модалок 
 */
const InfoPortalModal = ({onClose, mainText, onAccept}) => {

    return (
        <Modal onClose={onClose}
               style={{width: 600}}
               title={"Внимание"}>
            <Box style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
            }}>
                <p>{mainText}</p>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "240px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px"
                            }}>
                        Отмена
                    </Button>
                    <Button variant="contained"
                            onClick={onAccept}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "280px",
                                borderRadius: 2,
                                textTransform: "none"
                            }}>
                        Продолжить
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
};

export default InfoPortalModal;
