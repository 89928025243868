import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import "./style.scss";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from '@mui/utils';

const TableNew = (props) => {
    const {columns, rows, onRowClick, orderField, orderBy, maxHeight, needScroll, onRequestSort, hidePages, total, rowsPerPage, activePage, onPageChange, onRowsPerPageChange} = props;

    const createSortHandler = property => event => onRequestSort(event, property);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{maxHeight}}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        stickyHeader={needScroll}
                        size={'medium'}
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((headCell, i) => (
                                    <TableCell
                                        key={i}
                                        align={headCell.numeric ? 'right' : 'left'}
                                        style={{
                                            background: "#FAFAFA",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "rgba(0, 0, 0, 0.06)",
                                            ...(headCell.style ? headCell.style : {})
                                        }}
                                        padding={'normal'}>
                                        {
                                            headCell.sorted ?
                                                <TableSortLabel
                                                    active={orderField === headCell.field || orderField === headCell.sorted_field}
                                                    direction={(orderField === headCell.field || orderField === headCell.sorted_field) ? orderBy : 'asc'}
                                                    onClick={createSortHandler(headCell.sorted_field || headCell.field)}
                                                >
                                                    {headCell.label}
                                                    {
                                                        (orderField === headCell.field ||
                                                            orderField === headCell.sorted_field) ?
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                            : null}
                                                </TableSortLabel>
                                                :
                                                headCell.label
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={e => !e.target.closest('.icons-wrapper-table') && onRowClick ? onRowClick(row) : null}
                                            style={{cursor: onRowClick ? "pointer" : "default"}}
                                            tabIndex={-1}
                                            key={index}>
                                            {columns.map((headCell, i) => {
                                                if (headCell.field === "actions") {
                                                    return (
                                                        <TableCell
                                                            key={`${index}-${i}`}
                                                            align={headCell.numeric ? 'right' : 'left'}
                                                            style={{
                                                                padding: "8px 16px",
                                                                borderWidth: "1px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgba(0, 0, 0, 0.06)",
                                                                ...(headCell.style ? headCell.style : {})
                                                            }}
                                                            padding={'normal'}>
                                                            <div className="icons-wrapper-table"
                                                                 style={{cursor: "pointer"}}>
                                                                {row[headCell.field]}
                                                            </div>
                                                        </TableCell>
                                                    )
                                                }

                                                return (
                                                    <TableCell
                                                        key={`${index}-${i}`}
                                                        align={headCell.numeric ? 'right' : 'left'}
                                                        style={{
                                                            padding: "8px 16px",
                                                            borderWidth: "1px",
                                                            borderStyle: "solid",
                                                            borderColor: "rgba(0, 0, 0, 0.06)",
                                                            ...(headCell.style ? headCell.style : {})
                                                        }}
                                                        padding={'normal'}>
                                                        {row[headCell.field]}
                                                    </TableCell>
                                                )}
                                            )}
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    !hidePages &&
                    rows.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={activePage-1}
                        onPageChange={(e, p) => onPageChange(p+1)}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                }
            </Paper>
        </Box>
    )
};

export default TableNew;
