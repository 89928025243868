import React from "react";
import {observer} from "mobx-react";

/**
 * Компонент отрисовки статуса заявки
 */
const Status = observer(({color, text}) => {
    return <div style={{
        display: "flex",
        alignItems: "center"
    }}>
        <span style={{
        minWidth: "9px",
        width: "9px",
        height: "9px",
        borderRadius: "50px",
        marginRight: "4px",
        display: "block",
        backgroundColor: color}}/>
        {text}
    </div>
});

export default Status;
