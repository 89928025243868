import {makeAutoObservable} from "mobx"

const TEST_USER = {
    name: "Авторизован"
};

export default class UserStore {
    user = null;
    rootStore = null;
    checking = true;

    constructor(rootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * Авторизация
     */
    login = async (username, password) => {
        const token = btoa(`${username}:${password}`);
        localStorage.setItem("token", token);
        this.user = TEST_USER;
    };

    /**
     * Метод проверки наличия действующей авторизации
     */
    checkAuth = () => {
        if (!localStorage.getItem("token")) {
            this.checking = false;
            return null;
        }
        this.user = TEST_USER;
        this.checking = false;
    };

    /**
     * выход из профиля
     */
    logout = () => {
        this.user = null;
        localStorage.removeItem("token");
    }
}
