import axios from "axios";
import {makeAutoObservable} from "mobx";
import {API, AUTH_HEADERS} from "../../../config";
import get from "lodash/get";
import {USER_STATE} from "../../../consts";

export default class Data {
    user = {};
    toastStore;
    userStore;
    closeEvent;
    updateList;

    constructor(user, toastStore, userStore, closeEvent, updateList) {
        makeAutoObservable(this);
        this.user = user;
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.closeEvent = closeEvent;
        this.updateList = updateList;
    }

    /**
     * Номер телефона
     */
    get userPhone() {
        const phone = this.user.msisdn || this.user.msisdnCandidate;
        if (phone)
            return `${phone[0] === "+" ? "" : "+"}${phone}`;
        else
            return "";
    }

    /**
     * Флаг блокировки всех действий на форме
     */
    get disabledForm() {
        return this.user.state !== USER_STATE.ACTIVE;
    }

    /**
     * Список влитых учеток
     */
    get userAccountsMerged() {
        return get(this.user, "userAccountsMerged", []);
    }

    /**
     * Получение пользователя через API
     */
    loadUser = () => {
        axios.get(`${API}/admin/user-accounts/${this.user.id}`, {headers: AUTH_HEADERS()})
            .then(r => {
                this.user = r.data;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка данных о пользователе");
                this.closeEvent();
            })
    };

    /**
     * Валидация входных данны 
     */
    validateInput = (field, value) => {
        switch (field) {
            case "name":
            case "surname":
            case "patronymic":
                return !!value.match(/[a-zA-Zа-яА-Я]+$/gm);
            case "msisdn":
                return !!value.match(/[+0-9]+$/gm) && value.length <= 12 && value[0] === '+' && (value.length > 1 ? value[1] === '7' : true);
            default:
                return true;
        }
    };

    /**
     * Изменение данных на форме
     */
    changeUser = (field, value) => {
        if (!value) {
            this.user[field] = value.replace(/\s/g, '');
        }
        if (this.validateInput(field, value)) {
            this.user[field] = value.replace(/\s/g, '');
        }
    };

    /**
     * Валидация почты
     */
    validateEmail = () => {
        if (!this.user.emailAddress)
            return true;

        return !!String(this.user.emailAddress.replace(/\s/g, ''))
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    /**
     * 
     * @returns валидация телефона
     */
    validatePhone = () => {
        if (!this.user.msisdn)
            return true;

        return !!String(this.user.msisdn.replace(/\s/g, ''))
            .toLowerCase()
            .match(/[+0-9]+$/gm);
    };

    /**
     * Сохранение внесенных в пользователя изменений
     */
    save = () => {
        if (!this.user.msisdn && !this.user.emailAddress) {
            this.toastStore.showError("Введите телефон или email");
            return;
        }
        if (!this.validateEmail()) {
            this.toastStore.showError("Некорректный email");
            return;
        }
        if (!this.validatePhone()) {
            this.toastStore.showError("Некорректный телефон");
            return;
        }
        const data = {
            emailAddress: (this.user.emailAddress || "").replace(/\s/g, ''),
            surname: this.user.surname,
            name: this.user.name,
            patronymic: this.user.patronymic,
            origin: this.user.origin,
            state: this.user.state,
            msisdn: (this.user.msisdn || "").replace(/\s/g, '')
        };
        if (data.msisdn.length && data.msisdn[0] === "+") {
            data.msisdn = data.msisdn.substring(1);
        }
        axios.patch(`${API}/admin/user-accounts/${this.user.id}`, data, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Изменения сохранены");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    };

    /**
     * Блокировка/Разблокировка пользователя
     */
    changeStateUser = (state) => {
        const data = {state};
        axios.patch(`${API}/admin/user-accounts/${this.user.id}`, data, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess(state === USER_STATE.BLOCKED ? "Пользователь заблокирован" : "Пользователь разаблокирован");
                this.updateList();
                this.user.state = state;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    };

    /**
     * Удаление пользователя
     */
    delete = () => {
        axios.delete(`${API}/admin/user-accounts/${this.user.id}`, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Пользователь удален");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    };

    /**
     * Изменение пароля
     */
    changePassword = () => {
        axios.post(`${API}/admin/user-accounts/${this.user.id}/password`, {}, {headers: AUTH_HEADERS()})
            .then(r => {
                this.user.password = r.data.password;
                this.toastStore.showSuccess("Пароль сброшен");
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    };

    /**
     * Отмена ранее завершенного слияния аккаунтов
     */
    unbindRequest = (id) => {
        axios.put(`${API}/admin/user-account-merge-requests/${id}/merge/undo`, {}, {headers: AUTH_HEADERS()})
            .then(r => {
                this.loadUser();
                this.toastStore.showSuccess("Аккаунт успешно откреплен");
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                const localizedMessage = get(error.response, "data.localizedMessage", null);
                const message = get(error.response, "data.message", null);
                this.toastStore.showError(localizedMessage || message);
            })
    };
}
