import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import RootStoreProvider from "./RootStoreProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';

const theme = createTheme(
    {},
    ruRU,
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <RootStoreProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </RootStoreProvider>
);
