import axios from "axios";
import {makeAutoObservable} from "mobx";
import {API, AUTH_HEADERS} from "../../../config";
import {REQUEST_STATE} from "../../../consts";

export default class Data {
    request = {};
    toastStore;
    userStore;
    closeEvent;
    updateList;

    constructor(request, toastStore, userStore, closeEvent, updateList) {
        makeAutoObservable(this);
        this.request = request;
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.closeEvent = closeEvent;
        this.updateList = updateList;
    }

    /**
     * Загрузка подробностей по слиянию
     */
    loadRequest = () => {
        axios.get(`${API}/admin/user-account-merge-requests/${this.request.id}`, {headers: AUTH_HEADERS()})
            .then(r => {
                this.request = r.data;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка получения данных о слиянии");
                this.closeEvent();
            })
    };

    /**
     * Получение текстового значения статуса
     */
    get toState() {
        switch (this.request.toState) {
            case "CREATED":
                return "Создан";
            case "PROVEN":
                return "Подтвержден";
            case "CANCELLED":
                return "Отменен пользователем";
        }
    }

    /**
     * Получение текстового значения статуса
     */
    get requestState() {
        switch (this.request.state) {
            case REQUEST_STATE.CREATED:
                if (this.request.fromState === "DECLINED")
                    return "Отклонено пользователем";
                if (this.request.toState === "CANCELLED")
                    return "Отозвано пользователем";
                return "На рассмотрении";
            case REQUEST_STATE.MERGED:
                return "Одобрена";
            case REQUEST_STATE.CLOSED:
                return "Отказано";
            case REQUEST_STATE.DENIED:
                return "Заблокирована";
            case REQUEST_STATE.UNMERGED:
                return "Слияние отменено";
        }
    };

    /**
     * Получение текстового значения статуса
     */
    get fromState() {
        if (this.request.state === "CLOSED" && this.request.userAccountFrom?.state === "merged") {
            return "Объединен с другим аккаунтом"
        }
        switch (this.request.fromState) {
            case "CREATED":
                return "Создан";
            case "CONFIRMED":
                return "Подтвержден";
            case "DECLINED":
                return "Отклонен";
        }
    }

    /**
     * Флаг необходимости блокировки действией в попапе
     */
    get disabledForm() {
        return this.request.state === REQUEST_STATE.MERGED ||
               this.request.state === REQUEST_STATE.UNMERGED ||
               this.request.userAccountFrom?.state === "merged" ||
               this.beforeMerged;
    }

    /**
     * Флаг невозможности проведения операций над слитым ужуе аккаунтом в другой
     */
    get beforeMerged() {
        return this.request.state !== REQUEST_STATE.CREATED && !!this.request.userAccountTo?.esiaId;
    }

    /**
     * Получение дочернего аккканута
     */
    get daughterAccount() {
        return this.request.userAccountFrom;
    }

    /**
     * Одобрение заявки
     */
    merge = () => {
        axios.put(`${API}/admin/user-account-merge-requests/${this.request.id}/merge`, {}, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Заявка одобрена");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка одобрения слияния");
            })
    };

    /**
     * Блокировка заявки
     */
    deny = () => {
        axios.put(`${API}/admin/user-account-merge-requests/${this.request.id}/deny`, {}, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Заявка заблокирована");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка блокировки слияния");
            })
    };

    /**
     * Отказ в заявке
     */
    close = () => {
        axios.put(`${API}/admin/user-account-merge-requests/${this.request.id}/close`, {}, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Заявка отказана");
                this.updateList();
                this.closeEvent();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка отказа в слиянии");
            })
    };
}
