import React from "react";
import Box from "@mui/material/Box";
import {USER_STATE} from "../../../consts";
import BlockedUserIcon from "../../../icons/BlockedUserIcon";
import MultipleUserIcon from "../../../icons/MultipleUserIcon";
import Tooltip from "@mui/material/Tooltip";

/**
 * Компонент ячеки пользователя в таблице 
 */
const UserNameCell = ({user, fio}) => {
    return (
        <Box style={{
            display: "flex",
            alignItems:"center",
            justifyContent: "space-between",
            width: "100%"
        }}>
            {fio}
            <Box style={{
                display: "flex",
                alignItems:"center",
                justifyContent: "flex-start"
            }}>
                {
                    user.multiAccount &&
                    <Tooltip title="Мультиаккаунт">
                        <Box style={{marginRight: 4, height: 18}}>
                            <MultipleUserIcon />
                        </Box>
                    </Tooltip>
                }
                {
                    user.state === USER_STATE.BLOCKED &&
                    <Tooltip title="Заблокирован">
                        <Box style={{height: 18}}>
                            <BlockedUserIcon />
                        </Box>
                    </Tooltip>
                }
            </Box>
        </Box>
    )
};

export default UserNameCell;
