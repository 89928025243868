import React from "react";

const MultipleUserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 10.6875C7.656 10.6875 6.5625 9.594 6.5625 8.25C6.5625 6.906 7.656 5.8125 9 5.8125C10.344 5.8125 11.4375 6.906 11.4375 8.25C11.4375 9.594 10.344 10.6875 9 10.6875ZM9 7.3125C8.48287 7.3125 8.0625 7.73287 8.0625 8.25C8.0625 8.76712 8.48287 9.1875 9 9.1875C9.51712 9.1875 9.9375 8.76712 9.9375 8.25C9.9375 7.73287 9.51712 7.3125 9 7.3125Z" fill="#1890FF"/>
            <path d="M9 16.3125C7.00237 16.3125 5.4375 15.2419 5.4375 13.875V12.9375C5.4375 12.1102 6.11025 11.4375 6.9375 11.4375H11.0625C11.8898 11.4375 12.5625 12.1102 12.5625 12.9375V13.875C12.5625 15.2419 10.9976 16.3125 9 16.3125ZM6.9375 12.9375V13.875C6.9375 14.2481 7.75988 14.8125 9 14.8125C10.2401 14.8125 11.0625 14.2481 11.0625 13.875V12.9375H6.9375Z" fill="#1890FF"/>
            <path d="M6.9375 10.6875H6.95025C6.25575 10.1021 5.8125 9.22725 5.8125 8.25C5.8125 8.12288 5.82188 7.99837 5.8365 7.875H2.8125C2.08875 7.875 1.5 8.46375 1.5 9.1875V10.125C1.5 11.3614 2.92462 12.3326 4.7625 12.3713C5.0145 11.4045 5.89275 10.6875 6.9375 10.6875Z" fill="#1890FF"/>
            <path d="M15.1876 7.875H12.1636C12.1782 7.99837 12.1876 8.12288 12.1876 8.25C12.1876 9.22725 11.7443 10.1021 11.0498 10.6875H11.0626C12.1073 10.6875 12.9856 11.4045 13.2376 12.3713C15.0754 12.3326 16.5001 11.3614 16.5001 10.125V9.1875C16.5001 8.46375 15.9113 7.875 15.1876 7.875Z" fill="#1890FF"/>
            <path d="M9 10.6875C7.656 10.6875 6.5625 9.594 6.5625 8.25C6.5625 6.906 7.656 5.8125 9 5.8125C10.344 5.8125 11.4375 6.906 11.4375 8.25C11.4375 9.594 10.344 10.6875 9 10.6875ZM9 7.3125C8.48287 7.3125 8.0625 7.73287 8.0625 8.25C8.0625 8.76712 8.48287 9.1875 9 9.1875C9.51712 9.1875 9.9375 8.76712 9.9375 8.25C9.9375 7.73287 9.51712 7.3125 9 7.3125Z" fill="#1890FF"/>
            <path d="M9 16.3125C7.00237 16.3125 5.4375 15.2419 5.4375 13.875V12.9375C5.4375 12.1102 6.11025 11.4375 6.9375 11.4375H11.0625C11.8898 11.4375 12.5625 12.1102 12.5625 12.9375V13.875C12.5625 15.2419 10.9976 16.3125 9 16.3125ZM6.9375 12.9375V13.875C6.9375 14.2481 7.75988 14.8125 9 14.8125C10.2401 14.8125 11.0625 14.2481 11.0625 13.875V12.9375H6.9375Z" fill="#1890FF"/>
            <path d="M4.875 7.125C6.11764 7.125 7.125 6.11764 7.125 4.875C7.125 3.63236 6.11764 2.625 4.875 2.625C3.63236 2.625 2.625 3.63236 2.625 4.875C2.625 6.11764 3.63236 7.125 4.875 7.125Z" fill="#1890FF"/>
            <path d="M13.125 7.125C14.3676 7.125 15.375 6.11764 15.375 4.875C15.375 3.63236 14.3676 2.625 13.125 2.625C11.8824 2.625 10.875 3.63236 10.875 4.875C10.875 6.11764 11.8824 7.125 13.125 7.125Z" fill="#1890FF"/>
        </svg>
    )
};

export default MultipleUserIcon;
