import axios from "axios";
import {makeAutoObservable} from "mobx";
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ROWS_PER_PAGE} from "../default_values";
import React from "react";
import {API, AUTH_HEADERS} from "../../config";

export default class Data {
    organizations = [];
    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    activePage = DEFAULT_ACTIVE_PAGE;
    loading = false;
    total = 10;
    toastStore;
    userStore;
    openEditModal = null;
    orderField = "name";
    orderBy = "asc";

    constructor(toastStore, userStore, openEditModal) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.openEditModal = openEditModal;
    }

    /**
     * Изменение сортировки
     */
    onChangeSortingType = (event, property) => {
        const isAsc = this.orderField === property && this.orderBy === 'asc';
        this.orderBy = isAsc ? 'desc' : 'asc';
        this.orderField = property;
        this.getOrganizations();
    };

    /**
     * Список колонок для таблицы
     */
    get columns() {
        return [
            {
                label: "Наименование",
                numeric: false,
                field: "name",
                sorted: true,
                sorted_field: "name",
                style: {width: 1100}
            },
            {
                label: "ИНН",
                numeric: false,
                field: "inn",
                sorted: true,
                sorted_field: "inn",
            }
        ]
    }

    getOrganizations = () => {
        this.loading = true;
        const data = {
            offset: (this.activePage-1) * this.rowsPerPage,
            limit: this.rowsPerPage,
            sortBy: [{
                field: this.orderField,
                direction: this.orderBy.toUpperCase()
            }]
        };
        axios.post(`${API}/admin/organizations/filter`, data, {headers: AUTH_HEADERS()})
            .then(r => {
                this.organizations = r.data.data;
                this.total = r.data.total;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                }
            });
        this.loading = false;
    };

    onPageChange = page => {
        this.activePage = page;
        this.getOrganizations();
    };

    onRowsPerPageChange = count => {
        this.rowsPerPage = count;
        this.activePage = DEFAULT_ACTIVE_PAGE;
        this.getOrganizations();
    };

    onCloseModalUser = () => {
        this.openEditModal(null);
        this.getOrganizations();
    };
}
