import React, {useRef} from "react";
import { useNavigate } from "react-router-dom";
import Data from "./data";
import {useRootStore} from "../../RootStoreProvider";
import { observer } from "mobx-react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

/**
 * Страница атворизации
 */
const AuthPage = observer(() => {
    let navigate = useNavigate();
    const store = useRootStore();
    const data =  useRef(new Data(navigate, store.userStore));

    return (
        <Container component="main" maxWidth="xs"
                   sx={{
                       height: "100vh",
                       display: "flex",
                       alignItems: "center",
                       justifyContent: "center"
                   }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "380px",
                    padding: "40px 30px",
                    backgroundColor: "#fff"
                }}
            >
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Логин"
                        name="username"
                        autoComplete="email"
                        value={data.current.username}
                        onChange={e => data.current.username = e.target.value}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        value={data.current.password}
                        onChange={e => data.current.password = e.target.value}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Запомнить меня"
                    />
                    <Button
                        onClick={data.current.onAuth}
                        fullWidth
                        variant="contained"
                        disabled={!data.current.username || !data.current.password}
                        sx={{ mt: 3, mb: 2 }}>
                        Войти
                    </Button>
                </Box>
            </Box>
        </Container>
    );
});

export default AuthPage;
