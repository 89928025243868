import axios from "axios";
import {makeAutoObservable} from "mobx";
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ROWS_PER_PAGE} from "../default_values";
import React from "react";
import {API, AUTH_HEADERS} from "../../config";
import dayjs from "dayjs";

export default class Data {
    operations = [];
    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    activePage = DEFAULT_ACTIVE_PAGE;
    loading = false;
    total = 10;
    toastStore = null;
    userStore = null;
    fields = [];
    orderField = "createdAt";
    orderBy = "desc";

    constructor(toastStore, userStore) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = userStore;
    }

    /**
     * Изменение сортировки
     */
    onChangeSortingType = (event, property) => {
        const isAsc = this.orderField === property && this.orderBy === 'asc';
        this.orderBy = isAsc ? 'desc' : 'asc';
        this.orderField = property;
        this.getOperations();
    };

    /**
     * Список колонок для таблицы
     */
    get columns() {
        return [
            {
                label: "IP",
                numeric: false,
                field: "ip",
                sorted: true,
                sorted_field: "ip",
                style: {width: 150}
            },
            {
                label: "Тип логина",
                numeric: false,
                field: "loginType",
                sorted: true,
                sorted_field: "loginType",
                style: {width: 100}
            },
            {
                label: "Значение логина",
                numeric: false,
                field: "loginUserName",
                sorted: false,
                style: {width: 200}
            },
            {
                label: "Описание",
                numeric: false,
                field: "description",
                sorted: true,
                sorted_field: "description",
                style: {width: 400}
            },
            {
                label: "Дата авторизации",
                numeric: false,
                field: "updatedAtString",
                sorted: true,
                sorted_field: "updatedAt",
            },
            {
                label: "Дата создания",
                numeric: false,
                field: "createdAtString",
                sorted: true,
                sorted_field: "createdAt",
            }
        ]
    }

    getOperations = async () => {
        this.loading = true;
        const data = {
            offset: (this.activePage-1) * this.rowsPerPage,
            limit: this.rowsPerPage,
            sortBy: [{
                field: this.orderField,
                direction: this.orderBy.toUpperCase()
            }]
        };
        let response;
        try {
            response = await axios.post(`${API}/admin/login-errors/filter`, data, {headers: AUTH_HEADERS()});
        }
        catch (e) {
            this.loading = false;
            if (e.response.status === 401) {
                this.userStore.logout();
                return;
            }
        }
        this.operations = response.data.data.map(i =>  {
            return {
                ...i,
                createdAtString: dayjs(i.createdAt).format("DD.MM.YYYY HH:mm"),
                updatedAtString: dayjs(i.updatedAt).format("DD.MM.YYYY HH:mm")
            }
        });
        this.total = response.data.total;
        this.loading = false;
    };

    getFields = async () => {
        this.loading = true;
        const response = await axios.get(`${API}/admin/login-errors/.doc/fields`, {headers: AUTH_HEADERS()});
        this.fields = response.data
    };

    onPageChange = page => {
        this.activePage = page;
        this.getOperations();
    };

    onRowsPerPageChange = count => {
        this.rowsPerPage = count;
        this.activePage = DEFAULT_ACTIVE_PAGE;
        this.getOperations();
    };
}
