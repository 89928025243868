import React, {useEffect, useRef} from "react";
import "./style.scss";
import Modal from "../../Elements/Modal";
import Data from "./data";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import {useRootStore} from "../../../RootStoreProvider";
import _get from "lodash/get";

/**
 * Модалка с подробностями о запросе на слияние
 */
const DetailsRequestModal = observer(({onClose, updateList, request}) => {
    const store = useRootStore();
    const data = useRef(new Data(request, store.toastStore, store.userStore, onClose, updateList));

    useEffect(() => {
        data.current.loadRequest();
    }, []);

    const getBgColor = () => {
        switch (data.current.request.state) {
            case "CREATED":
                if (data.current.request.fromState === "DECLINED")
                    return "#FF4D4F";
                if (data.current.request.toState === "CANCELLED")
                    return "#FF4D4F";
                return "#D9D9D9";
            case "UNMERGED":
                return "#D9D9D9";
            case "MERGED":
                return "#11DD7B";
            case "CLOSED":
                return "#FF4D4F";
            case "DENIED":
                return "#FFF566";
        }
    };

    return (
        <Modal onClose={onClose}
               style={{width: 1260}}
               title={data.current.beforeMerged ? "Заявка на слияние (слияние ЕСИА аккаунтов не возможно)" : "Заявка на слияние"}>
            <Box style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginTop: "40px",
                position: "relative"
            }}>
                <p style={{
                    position: "absolute",
                    top: "-40px",
                    margin: 0,
                    fontSize: 13,
                    color: "gray"
                }}>
                    Статус заявки
                    <span style={{
                        color: "white",
                        padding: "2px 6px",
                        fontSize: 11,
                        borderRadius: 8,
                        marginLeft: 8,
                        backgroundColor: getBgColor()
                    }}>
                        {data.current.requestState}
                    </span>
                </p>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                }}>
                    <Box style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        borderRight: "1px solid rgba(0, 0, 0, 0.06)"
                    }}>
                        <p className="column-title">Родительский аккаунт</p>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Логин</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.login", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">E-mail</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.emailAddress", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Фамилия</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.surname", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Имя</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.name", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Отчество</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.patronymic", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Телефон</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.msisdn", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">СНИЛС</p>
                                <p className="value-text">{_get(data.current.request, "userAccountTo.snils", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Пользовательский статус заявки</p>
                                <p className="value-text">{data.current.toState}</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        paddingLeft: "20px",
                    }}>
                        <p className="column-title">Дочерний аккаунт</p>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Логин</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "login", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">E-mail</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "emailAddress", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Фамилия</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "surname", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Имя</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "name", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">Отчество</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "patronymic", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Телефон</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "msisdn", "-")}</p>
                            </Box>
                        </Box>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginRight: "20px",
                            }}>
                                <p className="label">СНИЛС</p>
                                <p className="value-text">{_get(data.current.daughterAccount, "snils", "-")}</p>
                            </Box>
                            <Box style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                            }}>
                                <p className="label">Пользовательский статус заявки</p>
                                <p className="value-text">{data.current.fromState}</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    paddingTop: "50px"
                }}>
                    <Box style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        marginTop: "28px",
                    }}>
                        <Button variant="contained"
                                onClick={data.current.deny}
                                disabled={data.current.disabledForm}
                                style={{
                                    color: "white",
                                    backgroundColor: data.current.disabledForm ? "gray" : "#FF4D4F",
                                    width: "216px",
                                    borderRadius: 2,
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginRight: "20px"
                                }}>
                            Заблокировать
                        </Button>
                        <Button variant="contained"
                                onClick={data.current.close}
                                disabled={data.current.disabledForm}
                                style={{
                                    color: "white",
                                    backgroundColor: data.current.disabledForm ? "gray" : "#1890FF",
                                    width: "163px",
                                    borderRadius: 2,
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginRight: "20px"
                                }}>
                            Отказать
                        </Button>
                        <Button variant="contained"
                                onClick={data.current.merge}
                                disabled={data.current.disabledForm}
                                style={{
                                    color: "white",
                                    backgroundColor: data.current.disabledForm ? "gray" : "rgba(11, 193, 106, 1)",
                                    width: "174px",
                                    borderRadius: 2,
                                    textTransform: "none",
                                    fontSize: "16px"
                                }}>
                            Одобрить
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
});

export default DetailsRequestModal;
