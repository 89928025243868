import React, {useRef} from "react";
import "./style.scss";
import Modal from "../../Elements/Modal";
import TextField from "@mui/material/TextField";
import Data from "./data";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import {useRootStore} from "../../../RootStoreProvider";

/**
 * Модалка создания нового пользователя
 */
const CreateUserModal = observer(({onClose, updateList}) => {
    const store = useRootStore();
    const data = useRef(new Data(store.toastStore, store.userStore, updateList, onClose));

    return (
        <Modal onClose={onClose}
               style={{width: 950}}
               title={"Добавить пользователя"}>
            <Box style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column"
            }}>
                <Box style={{
                    width: "284px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "30px",
                    marginBottom: "24px",
                }}>
                    <p className="label">Логин</p>
                    <TextField
                        size="small"
                        value={data.current.user.login}
                        onChange={e => data.current.changeUser("login", e.target.value)}
                        style={{width: "100%"}}
                        variant="outlined"/>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Имя</p>
                        <TextField
                            size="small"
                            value={data.current.user.name}
                            onChange={e => data.current.changeUser("name", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Фамилия</p>
                        <TextField
                            size="small"
                            value={data.current.user.surname}
                            onChange={e => data.current.changeUser("surname", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Отчество</p>
                        <TextField
                            size="small"
                            value={data.current.user.patronymic}
                            onChange={e => data.current.changeUser("patronymic", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">E-mail</p>
                        <TextField
                            size="small"
                            value={data.current.user.emailAddress}
                            onChange={e => data.current.changeUser("emailAddress", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Телефон</p>
                        <TextField
                            size="small"
                            value={data.current.user.msisdn}
                            placeholder={"+79998887766"}
                            onChange={e => data.current.changeUser("msisdn", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    marginTop: "28px",
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "230px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px",
                                fontSize: "16px"
                            }}>
                        Отмена
                    </Button>
                    <Button variant="contained"
                            onClick={data.current.create}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "260px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}>
                        Добавить пользователя
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
});

export default CreateUserModal;
