import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {useRootStore} from "../../RootStoreProvider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import TableNew from "../Elements/Table";
import Data from "./data";
import AddEditPortalModal from "./AddEditPortalModal";
import InfoPortalModal from "../InfoPortalModal";

/**
 * Страница со списком порталов
 */
const PortalsPage = observer(() => {
    const store = useRootStore();
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const data = useRef(new Data(store.toastStore, store.userStore, item => setDeleteItem(item)));

    useEffect(() => {
        store.appStore.setPageTitle("Управление порталами");
        data.current.search();
    }, []);

    return (
        <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <Box style={{
                display: "flex",
                alignItems:"center",
                justifyContent: "space-between",
                width: "100%",
                height: "64px"
            }}>
                <p style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.85)",
                    marginLeft: "8px"
                }}>
                    Управление порталами
                </p>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "514px"
                }}>
                    <Button variant="contained"
                            style={{marginRight: "8px", padding: "8px 16px"}}
                            onClick={() => setEditItem({id: -1})}
                            startIcon={<AddIcon />}>
                        Добавить портал
                    </Button>
                </Box>
            </Box>
            <TableNew columns={data.current.columns}
                      rows={data.current.portals}
                      rowsPerPage={data.current.rowsPerPage}
                      onRowClick={item => setEditItem(item)}
                      onRowsPerPageChange={p => data.current.onRowsPerPageChange(p.target.value)}
                      activePage={data.current.activePage}
                      total={data.current.total}
                      onPageChange={data.current.onPageChange}/>
            {
                editItem &&
                <AddEditPortalModal onClose={() => setEditItem(null)}
                                    portalId={editItem.id}
                                    updateList={data.current.search}/>
            }
            {
                deleteItem &&
                <InfoPortalModal onClose={() => setDeleteItem(null)}
                                 onAccept={() => {
                                     data.current.deletePortal(deleteItem.id);
                                     setDeleteItem(null);
                                 }}
                                 mainText={`Вы действительно хотите удалить портал "${deleteItem.clientId}"?`}/>
            }
        </Box>
    )
});

export default PortalsPage;
