import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useRootStore} from "../../RootStoreProvider";
import "./style.scss"
import TableNew from "../Elements/Table";
import Data from "./data";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

/**
 * Страница списка неудачных попыток авторизации
 */
const FailedTriesPage = observer(() => {
    const store = useRootStore();
    const data = useRef(new Data(store.toastStore, store.userStore));

    useEffect(() => {
        store.appStore.setPageTitle("Реестр неудачных попыток авторизации");
        data.current.getFields();
        data.current.getOperations();
    }, []);

    return (
        <Box className="wrapper">
            <Box style={{
                display: "flex",
                alignItems:"center",
                justifyContent: "space-between",
                width: "100%",
                height: "64px"
            }}>
                <p style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.85)",
                    marginLeft: "8px"
                }}>
                    Реестр неудачных попыток авторизации
                </p>
            </Box>
            <TableNew columns={data.current.columns}
                      rows={data.current.operations}
                      rowsPerPage={data.current.rowsPerPage}
                      onRowsPerPageChange={p => data.current.onRowsPerPageChange(p.target.value)}
                      activePage={data.current.activePage}
                      total={data.current.total}
                      onRequestSort={data.current.onChangeSortingType}
                      orderField={data.current.orderField}
                      orderBy={data.current.orderBy}
                      onPageChange={data.current.onPageChange}/>
            {
                data.current.loading &&
                <div className="loader">
                    <CircularProgress />
                </div>
            }
        </Box>
    )
});

export default FailedTriesPage;
