import axios from "axios";
import {makeAutoObservable} from "mobx";
import {API} from "../../../config";

export default class Data {
    organization = {};
    toastStore;
    closeEvent;

    constructor(organization, toastStore, closeEvent) {
        makeAutoObservable(this);
        this.organization = organization;
        this.toastStore = toastStore;
        this.closeEvent = closeEvent;
        this.loadOrganization();
    }

    loadOrganization = () => {
        /*axios.get(`${API}/admin/api/user-accounts/${this.user.id}`, {headers: AUTH_HEADERS()})
            .then(r => {
                console.log("r", r.data);
            })
            .catch(() => {
                this.toastStore.showError("Ошибка данных о пользователе");
                //this.closeEvent();
            })*/
    };

    changeOrganization = (field, value) => {
        this.organization[field] = value;
    };
}
