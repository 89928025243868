import React, {useState} from "react";
import Modal from "../../Elements/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import SearchIcon from "@mui/icons-material/Search";

/**
 * Модалка филдьтрации списка пользователй
 */
const FilterUsersModal = observer(({onClose, filters, onAccept}) => {
    const [filtersLocal, setFiltersLocal] = useState(filters);

    const onAcceptClick = () => {
        onAccept(filtersLocal);
        onClose();
    };

    return (
        <Modal onClose={onClose}
               style={{width: 728}}
               title={"Поиск по пользователям"}>
            <Box style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column"
            }}>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginTop: "30px",
                        marginRight: "20px"
                    }}>
                        <p className="label">Логин</p>
                        <TextField
                            size="small"
                            value={filtersLocal.login}
                            onChange={e => setFiltersLocal({...filtersLocal, login: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginTop: "30px",
                    }}>
                        <p className="label">Фамилия</p>
                        <TextField
                            size="small"
                            value={filtersLocal.surname}
                            onChange={e => setFiltersLocal({...filtersLocal, surname: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Имя</p>
                        <TextField
                            size="small"
                            value={filtersLocal.name}
                            onChange={e => setFiltersLocal({...filtersLocal, name: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Отчество</p>
                        <TextField
                            size="small"
                            value={filtersLocal.patronymic}
                            onChange={e => setFiltersLocal({...filtersLocal, patronymic: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Почта</p>
                        <TextField
                            size="small"
                            value={filtersLocal.emailAddress}
                            onChange={e => setFiltersLocal({...filtersLocal, emailAddress: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Телефон</p>
                        <TextField
                            size="small"
                            type="number"
                            value={filtersLocal.msisdn}
                            onChange={e => setFiltersLocal({...filtersLocal, msisdn: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "39px"
                }}>
                    <p className="status-label">Статус:</p>
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.disabled}
                                      onChange={() => setFiltersLocal({...filtersLocal, disabled: !filtersLocal.disabled})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Заблокирован"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.active}
                                      onChange={() => setFiltersLocal({...filtersLocal, active: !filtersLocal.active})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Активен"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.local}
                                      onChange={() => setFiltersLocal({...filtersLocal, local: !filtersLocal.local})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Локальный аккаунт"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.multiply}
                                      onChange={() => setFiltersLocal({...filtersLocal, multiply: !filtersLocal.multiply})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Мультиаккаунт"
                    />
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "230px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px",
                                fontSize: "16px"
                            }}>
                        Отмена
                    </Button>
                    <Button variant="contained"
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "260px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}
                            onClick={onAcceptClick}
                            startIcon={<SearchIcon />}>
                        Поиск
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
});

export default FilterUsersModal;
