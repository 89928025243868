import axios from "axios";
import {makeAutoObservable} from "mobx";
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ROWS_PER_PAGE} from "../default_values";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";
import _get from "lodash/get";
import {API, AUTH_HEADERS} from "../../config";
import UserNameCell from "./Components/UserNameCell";
import DeleteIcon from "../../icons/DeleteIcon";
import {USER_STATE} from "../../consts";

export default class Data {
    users = [];
    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    activePage = DEFAULT_ACTIVE_PAGE;
    loading = false;
    total = 10;
    toastStore = null;
    userStore = null;
    openPopupDeleteItem = null;
    orderField = "surname";
    orderBy = "asc";
    filters = {
        name: "",
        surname: "",
        patronymic: "",
        login: "",
        emailAddress: "",
        msisdn: "",
        disabled: false,
        active: false,
        local: false,
        multiply: false,
    };

    constructor(toastStore, userStore, openPopupDeleteItem) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.openPopupDeleteItem = openPopupDeleteItem;
    }

    /**
     * Изменение сортировки
     */
    onChangeSortingType = (event, property) => {
        const isAsc = this.orderField === property && this.orderBy === 'asc';
        this.orderBy = isAsc ? 'desc' : 'asc';
        this.orderField = property;
        this.getUserList();
    };

    /**
     * Список колонок для таблицы
     */
    get columns() {
        return [
            {
                label: "ФИО пользователя",
                style: {minWidth: "400px", width: "100%", padding: "8px 10px 8px 16px"},
                numeric: false,
                field: "fio",
                sorted: true,
                sorted_field: "surname",
            },
            {
                label: "Логин",
                style: {minWidth: "300px"},
                numeric: false,
                field: "login",
                sorted: true,
                sorted_field: "login",
            },
            {
                label: "Почта",
                style: {minWidth: "300px"},
                numeric: false,
                field: "emailAddress",
                sorted: true,
                sorted_field: "emailAddress",
            },
            {
                label: "Телефон",
                style: {minWidth: "300px"},
                numeric: false,
                field: "msisdnString",
                sorted: true,
                sorted_field: "msisdn",
            },
            {
                label: "Действие",
                style: {minWidth: "100px"},
                field: "actions"
            }
        ]
    }

    /**
     * Получение списка пользователей
     */
    getUserList = async () => {
        this.loading = true;
        const data = {
            offset: (this.activePage-1) * this.rowsPerPage,
            limit: this.rowsPerPage,
            filters: [{
                field: "state",
                eq: "merged",
                wildcard: true,
                must: false
            }],
            sortBy: [{
                field: this.orderField,
                direction: this.orderBy.toUpperCase()
            }]
        };
        //region Filters
        if (this.filters.name) {
            data.filters.push({
                field: "name",
                regExp: `%${this.filters.name}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.surname) {
            data.filters.push({
                field: "surname",
                regExp: `%${this.filters.surname}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.patronymic) {
            data.filters.push({
                field: "patronymic",
                regExp: `%${this.filters.patronymic}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.login) {
            data.filters.push({
                field: "login",
                regExp: `%${this.filters.login}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.emailAddress) {
            data.filters.push({
                field: "emailAddress",
                regExp: `%${this.filters.emailAddress}%`,
                ignoreCase: true,
            });
        }
        if (this.filters.msisdn) {
            data.filters.push({
                field: "msisdn",
                regExp: `%${this.filters.msisdn}%`
            });
        }
        if (this.filters.disabled) {
            if (this.filters.active) {
                data.filters.push({
                    field: "state",
                    //eq: USER_STATE.BLOCKED,
                    in: [USER_STATE.ACTIVE, USER_STATE.BLOCKED]
                });
            }
            else {
                data.filters.push({
                    field: "state",
                    eq: USER_STATE.BLOCKED
                });
            }
        }
        else if (this.filters.active) {
            data.filters.push({
                field: "state",
                eq: USER_STATE.ACTIVE
            });
        }
        if (this.filters.local) {
            if (!this.filters.multiply) {
                data.filters.push({
                    field: "multiAccount",
                    must: false
                });
            }
        }
        else if (this.filters.multiply) {
            data.filters.push({
                field: "multiAccount",
                must: true
            });
        }
        //endregion
        let response;
        try {
            response = await axios.post(`${API}/admin/user-accounts/filter`, data, {headers: AUTH_HEADERS()});
        }
        catch (e) {
            if (e.response.status === 401) {
                this.userStore.logout();
                return;
            }
        }
        this.users = response.data.data.map(i =>  {
            const fio = `${_get(i, "surname", "")} ${_get(i, "name", "")} ${_get(i, "patronymic", "")}`;
            const phone = i.msisdn || i.msisdnCandidate;
            return {
                ...i,
                fio: <UserNameCell user={i} fio={fio}/>,
                emailAddress: i.emailAddress || i.emailAddressCandidate,
                msisdnString: phone ? `+${phone}` : "",
                actions: <>
                    <Tooltip title="Удалить">
                        <ListItemIcon onClick={() => this.openPopupDeleteItem({...i, fio})}>
                            <DeleteIcon />
                        </ListItemIcon>
                    </Tooltip>
                </>
            }
        });
        this.total = response.data.total;
        this.loading = false;
    };

    /**
     * Удаление пользователя
     */
    onDeleteUser = async id => {
        try {
            await axios.delete(`${API}/admin/user-accounts/${id}`, {headers: AUTH_HEADERS()});
            this.getUserList();
        }
        catch (e) {
            if (e.response.status === 401) {
                this.userStore.logout();
                return;
            }
            const localizedMessage = _get(e.response, "data.localizedMessage", null);
            const message = _get(e.response, "data.message", null);
            this.toastStore.showError(localizedMessage || message);
        }
    };

    /**
     * Изменение текущей страницы
     */
    onPageChange = page => {
        this.activePage = page;
        this.getUserList();
    };

    /**
     * Изменение количества элементов на странице
     */
    onRowsPerPageChange = count => {
        this.rowsPerPage = count;
        this.onPageChange(DEFAULT_ACTIVE_PAGE);
    };

    /**
     * Функция применения фильтров
     */
    changeFilters = filters => {
        this.filters = filters;
        this.onPageChange(DEFAULT_ACTIVE_PAGE);
    };
}
