import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {useRootStore} from "../../RootStoreProvider";
import "./style.scss"
import Button from "@mui/material/Button";
import TableNew from "../Elements/Table";
import Data from "./data";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import CreateUserModal from "./CreateUserModal";
import DetailsUserModal from "./DetailsUserModal";
import InfoPortalModal from "../InfoPortalModal";
import FilterUsersModal from "./FilterUsersModal";

/**
 * Страница списка пользователей
 */
const UsersPage = observer(() => {
    const store = useRootStore();
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const data = useRef(new Data(store.toastStore, store.userStore, item => setDeleteItem(item)));

    useEffect(() => {
        store.appStore.setPageTitle("Пользователи");
        data.current.getUserList();
    }, []);

    const onCloseCreateModal = () => {
        setShowCreateModal(false);
        data.current.getUserList();
    };

    return (
        <Box className="wrapper">
            <Box style={{
                display: "flex",
                alignItems:"center",
                justifyContent: "space-between",
                width: "100%",
                height: "64px"
            }}>
                <p style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.85)",
                    marginLeft: "8px"
                }}>
                    Управление пользователями
                </p>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "514px"
                }}>
                    <Button variant="contained"
                            style={{marginRight: "10px", padding: "8px 30px"}}
                            onClick={() => setShowFilterModal(true)}
                            startIcon={<SearchIcon />}>
                        Поиск
                    </Button>
                    <Button variant="contained"
                            style={{marginRight: "8px", padding: "8px 16px"}}
                            onClick={() => setShowCreateModal(true)}
                            startIcon={<AddIcon />}>
                        Добавить пользователя
                    </Button>
                </Box>
            </Box>
            <TableNew columns={data.current.columns}
                      rows={data.current.users}
                      rowsPerPage={data.current.rowsPerPage}
                      onRowsPerPageChange={p => data.current.onRowsPerPageChange(p.target.value)}
                      activePage={data.current.activePage}
                      total={data.current.total}
                      onRequestSort={data.current.onChangeSortingType}
                      orderField={data.current.orderField}
                      orderBy={data.current.orderBy}
                      onRowClick={item => setEditItem(item)}
                      onPageChange={data.current.onPageChange}/>
            {
                showFilterModal &&
                <FilterUsersModal onClose={() => setShowFilterModal(false)}
                                  filters={data.current.filters}
                                  onAccept={data.current.changeFilters}/>
            }
            {
                showCreateModal &&
                <CreateUserModal onClose={onCloseCreateModal}
                                 updateList={data.current.getUserList}/>
            }
            {
                editItem &&
                <DetailsUserModal onClose={() => setEditItem(null)}
                                  updateList={data.current.getUserList}
                                  user={editItem}/>
            }
            {
                deleteItem &&
                <InfoPortalModal onClose={() => setDeleteItem(null)}
                                 onAccept={() => {
                                     data.current.onDeleteUser(deleteItem.id);
                                     setDeleteItem(null);
                                 }}
                                 mainText={`Вы действительно хотите удалить пользователя "${deleteItem.fio}"?`}/>
            }
            {
                data.current.loading &&
                <div className="loader">
                    <CircularProgress />
                </div>
            }
        </Box>
    )
});

export default UsersPage;
