import React, {useState} from "react";
import Modal from "../../Elements/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import SearchIcon from "@mui/icons-material/Search";

/**
 * Модалка фильтрации и поиска по слияниям
 */
const FilterRequestsModal = observer(({onClose, filters, onAccept}) => {
    const [filtersLocal, setFiltersLocal] = useState(filters);

    /**
     * Приминение фильтров
     */
    const onAcceptClick = () => {
        onAccept(filtersLocal);
        onClose();
    };

    return (
        <Modal onClose={onClose}
               style={{width: 728}}
               title={"Поиск по аккаунтам"}>
            <Box style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column"
            }}>
                <p className="title-group">Родительский аккаунт</p>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Фамилия</p>
                        <TextField
                            size="small"
                            value={filtersLocal.parent_surname}
                            onChange={e => setFiltersLocal({...filtersLocal, parent_surname: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Имя</p>
                        <TextField
                            size="small"
                            value={filtersLocal.parent_name}
                            onChange={e => setFiltersLocal({...filtersLocal, parent_name: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Отчество</p>
                        <TextField
                            size="small"
                            value={filtersLocal.parent_patronymic}
                            onChange={e => setFiltersLocal({...filtersLocal, parent_patronymic: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <p className="title-group">Дочерний аккаунт</p>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Фамилия</p>
                        <TextField
                            size="small"
                            value={filtersLocal.child_surname}
                            onChange={e => setFiltersLocal({...filtersLocal, child_surname: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px"
                    }}>
                        <p className="label">Имя</p>
                        <TextField
                            size="small"
                            value={filtersLocal.child_name}
                            onChange={e => setFiltersLocal({...filtersLocal, child_name: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "324px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Отчество</p>
                        <TextField
                            size="small"
                            value={filtersLocal.child_patronymic}
                            onChange={e => setFiltersLocal({...filtersLocal, child_patronymic: e.target.value})}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "39px"
                }}>
                    <p className="status-label">По статусам завяки:</p>
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.created}
                                      onChange={() => setFiltersLocal({...filtersLocal, created: !filtersLocal.created})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="На рассмотрении"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.merged}
                                      onChange={() => setFiltersLocal({...filtersLocal, merged: !filtersLocal.merged})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Одобрена"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.closed}
                                      onChange={() => setFiltersLocal({...filtersLocal, closed: !filtersLocal.closed})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Отказано"
                    />
                    <FormControlLabel
                        classes={{root: 'custom-form-control-root'}}
                        control={
                            <Checkbox color="primary"
                                      checked={filtersLocal.denied}
                                      onChange={() => setFiltersLocal({...filtersLocal, denied: !filtersLocal.denied})}
                                      classes={{root: 'custom-checkbox-root'}}/>
                        }
                        label="Заблокирована"
                    />
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "230px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px",
                                fontSize: "16px"
                            }}>
                        Отмена
                    </Button>
                    <Button variant="contained"
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "260px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}
                            onClick={onAcceptClick}
                            startIcon={<SearchIcon />}>
                        Поиск
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
});

export default FilterRequestsModal;
