import React, {useEffect} from "react";
import {useRootStore} from "./RootStoreProvider";
import {Routes, Route} from "react-router-dom";
import routes, {routesUnAuth} from "./routes";
import {observer} from "mobx-react";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Footer from "./components/Footer";
import "./AppStyle.scss";

const App = observer(() => {
  const store = useRootStore();

  useEffect(() => {
    store.userStore.checkAuth();
  }, []);

  if (store.userStore.checking)
    return null;

  return (
    <div className="App">
      <Snackbar open={!!store.toastStore.type}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
        <Alert severity={store.toastStore.type || "error"} sx={{ width: '100%' }}>
          {store.toastStore.msg}
        </Alert>
      </Snackbar>
      {
        store.userStore.user ?
            <Box sx={{ display: 'flex', flexDirection: "column" }}>
              <CssBaseline />
              <Header />
              <Menu />
              <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                margin: "0 10px",
                padding: "10px 10px 30px 10px",
                backgroundColor: "#fff"
              }}>
                <Routes>
                  {Object.values(routes).map((route, i) => <Route key={i} path={route.path} element={route.element}/>)}
                </Routes>
              </Box>
              <Footer/>
            </Box>
            :
            <Routes>
              {Object.values(routesUnAuth).map((route, i) => <Route key={i} {...route}/>)}
            </Routes>
      }
    </div>
  );
});

export default App;
