import React, {useEffect, useRef, useState} from "react";
import Modal from "../../Elements/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./index.scss";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Data from "./data";
import {observer} from "mobx-react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {useRootStore} from "../../../RootStoreProvider";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InfoPortalModal from "../../InfoPortalModal";

/**
 * Компонент аккордиона
 */
const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props}/>)
(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

/**
 * Компонент шапки аккордиона
 */
const AccordionSummary = styled(props =>
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props}/>)
(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

/**
 * Компонент подробностей аккордиона
 */
const AccordionDetails = styled(MuiAccordionDetails)
(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

/**
 * Модалка с подробностями о портале
 */
const AddEditPortalModal = observer(({onClose, portalId, updateList}) => {
    const store = useRootStore();
    const data = useRef(new Data(portalId, onClose, store.toastStore, store.userStore, updateList));
    const [expanded, setExpanded] = useState('keywords');
    const [deleteItem, setDeleteItem] = useState(null);

    useEffect(() => {
        data.current.loadPortal();
        data.current.loadScopeList();
    }, []);

    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Modal onClose={onClose}
               style={{width: 865}}
               title={portalId === -1 ? "Добавить портал" : "Редактировать портал"}>
            <Box style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
            }}>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: 30,
                    marginBottom: 24
                }}>
                    <p className="label">Наименование портала</p>
                    <TextField
                        size="small"
                        value={data.current.portal.clientId}
                        onChange={e => data.current.onChangeParameter("clientId", e.target.value)}
                        disabled={portalId !== -1}
                        style={{width: "100%"}}
                        variant="outlined"/>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: 24
                }}>
                    <p className="label">Адрес портала</p>
                    <TextField
                        size="small"
                        value={data.current.portal.rootUrl}
                        onChange={e => data.current.onChangeParameter("rootUrl", e.target.value)}
                        style={{width: "100%"}}
                        variant="outlined"/>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: 24
                }}>
                    <Box style={{
                        width: portalId !== -1 ? 257 : 394,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        marginRight: 17
                    }}>
                        <p className="label">Время жизни токена</p>
                        <TextField
                            size="small"
                            type={"number"}
                            value={data.current.portal.accessTokenLifespan}
                            onChange={e => data.current.onChangeParameter("accessTokenLifespan", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: portalId !== -1 ? 257 : 394,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        marginRight: 17
                    }}>
                        <p className="label">Client_id</p>
                        <TextField
                            size="small"
                            value={data.current.portal.clientId}
                            disabled={portalId !== -1}
                            onChange={e => data.current.onChangeParameter("clientId", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    {
                        portalId !== -1 &&
                        <Box style={{
                            width: 257,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }}>
                            <p className="label">Client_secret</p>
                            <TextField
                                size="small"
                                value={data.current.portal.clientSecret}
                                disabled={true}
                                style={{width: "100%"}}
                                variant="outlined"/>
                        </Box>
                    }
                </Box>
                <Box style={{
                    width: "100%",
                    marginBottom: 24
                }}>
                    <Accordion expanded={expanded === 'urls'}
                               style={{width: "100%"}}
                               onChange={handleChangeAccordion('urls')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Valid Redirect URIs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "flex-start"
                            }}>
                                {
                                    data.current.portal.redirectUris &&
                                    data.current.portal.redirectUris.map((url, i) => {
                                        return (
                                            <Box key={i}
                                                 style={{
                                                     marginRight: "8px",
                                                     marginBottom: "8px",
                                                     display: "flex",
                                                     alignItems: "center",
                                                     justifyContent: "center",
                                                     padding: "4px 2px 4px 16px",
                                                     backgroundColor: "#F5F5F5",
                                                     fontWeight: 400,
                                                     fontSize: "16px",
                                                     color: "rgba(0, 0, 0, 0.85)"
                                                 }}>
                                                {url}
                                                <IconButton onClick={() => data.current.removeUrl(url)}
                                                            style={{marginLeft: "4px"}}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                                marginTop: "8px"
                            }}>
                                <TextField
                                    sx={{width: "450px", marginRight: "10px"}}
                                    size="small"
                                    label="Новый url"
                                    variant="outlined"
                                    value={data.current.newUrl}
                                    onChange={data.current.onChangeInputNewUrl}
                                />
                                <Button variant="contained"
                                        onClick={() => data.current.onChangeInputNewUrl()}
                                        style={{
                                            color: "white",
                                            backgroundColor: "#1890FF",
                                            width: "180px",
                                            borderRadius: 2,
                                            textTransform: "none",
                                            fontSize: "16px"
                                        }}>
                                    Добавить
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'scopes'}
                               style={{width: "100%"}}
                               onChange={handleChangeAccordion('scopes')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Scope по-умолчанию</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "flex-start"
                            }}>
                                {
                                    data.current.portal.defaultClientScopes &&
                                    data.current.portal.defaultClientScopes.map((scope, i) => {
                                        return (
                                            <Box key={i}
                                                 style={{
                                                     marginRight: "8px",
                                                     marginBottom: "8px",
                                                     display: "flex",
                                                     alignItems: "center",
                                                     justifyContent: "center",
                                                     padding: "4px 2px 4px 16px",
                                                     backgroundColor: "#F5F5F5",
                                                     fontWeight: 400,
                                                     fontSize: "16px",
                                                     color: "rgba(0, 0, 0, 0.85)"
                                                 }}>
                                                {scope}
                                                <IconButton onClick={() => data.current.removeScope(scope)}
                                                            style={{marginLeft: "4px"}}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "flex-start",
                                marginTop: 8
                            }}>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    justifyContent: "flex-start"
                                }}>
                                    <Typography>Выберите из списка</Typography>
                                    <FormControl sx={{width: "284px"}}
                                                size="small">
                                        <Select
                                            autoWidth={true}
                                            onChange={data.current.onChangeSelectNewScope}>
                                            {
                                                (portalId === -1 ? data.current.scopes : (data.current.portal.availableClientScopes || []))
                                                .map((scope, i) => {
                                                    if ((data.current.portal.defaultClientScopes || []).indexOf(scope) === -1) {
                                                        return <MenuItem key={i} value={scope}>{scope}</MenuItem>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Typography sx={{margin: '0 16px 0 16px'}}>или</Typography>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    justifyContent: "flex-start"
                                }}>
                                    <Typography>Добавьте новый</Typography>
                                    <Box style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        justifyContent: "flex-start",
                                    }}>
                                        <TextField
                                            sx={{width: "284px", marginRight: "10px"}}
                                            size="small"
                                            label=""
                                            variant="outlined"
                                            value={data.current.newScope}
                                            onChange={data.current.onChangeInputNewScope}
                                        />
                                        <Button variant="contained"
                                                onClick={() => data.current.onChangeInputNewScope()}
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#1890FF",
                                                    width: "100px",
                                                    borderRadius: 2,
                                                    textTransform: "none",
                                                    fontSize: "16px"
                                                }}>
                                            Добавить
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "240px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px",
                                fontSize: "16px"
                            }}>
                        Отмена
                    </Button>
                    {
                        portalId !== -1 &&
                        <Button variant="contained"
                                onClick={() => setDeleteItem(true)}
                                style={{
                                    color: "white",
                                    backgroundColor: "#FF4D4F",
                                    width: "240px",
                                    borderRadius: 2,
                                    textTransform: "none",
                                    marginRight: "20px",
                                    fontSize: "16px"
                                }}>
                            Удалить портал
                        </Button>
                    }
                    <Button variant="contained"
                            onClick={data.current.createPortal}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "280px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}>
                        {portalId === -1 ? "Добавить портал" : "Сохранить изменения"}
                    </Button>
                </Box>
            </Box>
            {
                deleteItem &&
                <InfoPortalModal onClose={() => setDeleteItem(null)}
                                 onAccept={() => {
                                     setDeleteItem(null);
                                     data.current.deletePortal();
                                 }}
                                 mainText={`Вы действительно хотите удалить портал "${data.current.portal.clientId}"?`}/>
            }
        </Modal>
    )
});

export default AddEditPortalModal;
