import {makeAutoObservable} from "mobx";

const TIME = 4000;

/**
 * Хранилище всплывающих уведомлений
 */
export default class ToastStore {
    msg = "";
    type = null;

    constructor() {
        makeAutoObservable(this);
    }

    clear = () => {
        setTimeout(() => {
            this.msg = "";
            this.type = null;
        }, TIME);
    };

    showError = msg => {
        this.msg = msg;
        this.type = "error";
        this.clear();
    };

    showSuccess = msg => {
        this.msg = msg;
        this.type = "success";
        this.clear();
    };
}
