import {makeAutoObservable} from "mobx";
import React from "react";
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ROWS_PER_PAGE} from "../default_values";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import {API, AUTH_HEADERS} from "../../config";
import DeleteIcon from "../../icons/DeleteIcon";

export default class Data {
    portals = [];
    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    activePage = DEFAULT_ACTIVE_PAGE;
    total = 10;
    loading = false;
    toastStore;
    userStore;
    openPopupDeleteItem;

    constructor(toastStore, userStore, openPopupDeleteItem) {
        makeAutoObservable(this);
        this.toastStore = toastStore;
        this.userStore = userStore;
        this.openPopupDeleteItem = openPopupDeleteItem;
    }

    /**
     * Запрос данных для таблицы
     */
    search = async () => {
        this.loading = true;
        let response = [];
        try {
            response = await axios.get(`${API}/admin/clients?offset=${(this.activePage-1) * this.rowsPerPage}&limit=${this.rowsPerPage}`, {headers: AUTH_HEADERS()});
            
        }
        catch(e) {
            if (e.response.status === 401) {
                this.userStore.logout();
            }
        }
        this.total = response.data.total;
        this.portals = response.data.data.map(i =>  {
            return {
                ...i,
                actions: <>
                    <Tooltip title="Удалить">
                        <ListItemIcon onClick={() => this.openPopupDeleteItem(i)}>
                            <DeleteIcon />
                        </ListItemIcon>
                    </Tooltip>
                </>
            }
        });
    };

    /**
     * Список колонок для таблицы
     */
    get columns() {
        return [
            {
                label: "Наименование",
                numeric: false,
                field: "clientId",
                style: {
                    minWidth: 900,
                    width: "100%"
                }
            },
            {
                label: "Действие",
                numeric: false,
                field: "actions",
                style: {width: 100}
            }
        ]
    }

    onRowsPerPageChange = count => {
        this.rowsPerPage = count;
        this.activePage = DEFAULT_ACTIVE_PAGE;
        this.search();
    };

    onPageChange = page => {
        this.activePage = page;
        this.search();
    };

    /**
     * Удаление портала
     */
    deletePortal = id => {
        axios.delete(`${API}/admin/clients/${id}`, {headers: AUTH_HEADERS()})
            .then(() => {
                this.toastStore.showSuccess("Портал удален");
                this.search();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.userStore.logout();
                    return;
                }
                this.toastStore.showError("Ошибка удаления портала");
            });
    };
}
