import React from "react";
import Typography from "@mui/material/Typography";
import "./style.scss"
import ModalMui from "@mui/material/Modal";
import Box from "@mui/material/Box";

const Modal = (props) => {
    const {onClose, title, style} = props;
    return (
        <ModalMui
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="modal-wrapper"
                 style={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2"
                            style={{position: "relative"}}>
                    {title}
                    <Box style={{
                        position: "absolute",
                        top: "3px",
                        right: 0,
                        height: "22px",
                        cursor: "pointer"
                    }}
                         onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M1 1L11 11M21 21L11 11M11 11L21 1L1 21" stroke="#D9D9D9"/>
                        </svg>
                    </Box>
                </Typography>
                <div className="modal-body-wrapper">
                    {props.children}
                </div>
            </div>
        </ModalMui>
    )
};

export default Modal;
