import React from "react";

const BlockedUserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M8.04545 7.09091V6.13636C8.04545 5.61009 8.47373 5.18182 9 5.18182C9.52627 5.18182 9.95455 5.61009 9.95455 6.13636V7.09091H8.04545ZM16 9C16 12.8659 12.8659 16 9 16C5.13409 16 2 12.8659 2 9C2 5.13409 5.13409 2 9 2C12.8659 2 16 5.13409 16 9ZM12.1818 8.36364C12.1818 7.77118 11.7752 7.278 11.2273 7.13609V6.13636C11.2273 4.90818 10.2282 3.90909 9 3.90909C7.77182 3.90909 6.77273 4.90818 6.77273 6.13636V7.13609C6.22482 7.278 5.81818 7.77118 5.81818 8.36364V11.5455C5.81818 12.2486 6.38773 12.8182 7.09091 12.8182H10.9091C11.6123 12.8182 12.1818 12.2486 12.1818 11.5455V8.36364Z" fill="#FF4D4F"/>
        </svg>
    )
};

export default BlockedUserIcon;
