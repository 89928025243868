import {observer} from "mobx-react";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import routes from "../../routes";
import {useNavigate} from "react-router-dom";

/**
 * Компонент формирования и отрисовки Меню
 */
const Menu = observer(() => {
    const navigate = useNavigate();

    /**
     * Определяем, кокой пункт меню выделен
     */
    const selected = path => {
        return window.location.pathname === path;
    };

    return (
        <Toolbar sx={{pr: '24px', margin: "70px 10px 10px 10px", backgroundColor: "#fff"}}>
            <List component="nav" sx={{display: 'flex'}}>
                {
                    Object.values(routes).map((route, i) => {
                        const isSelected = selected(route.path);
                        const Icon = route.icon;
                        return (
                            <ListItemButton key={i}
                                            selected={isSelected}
                                            onClick={() => navigate(route.path)}>
                                <Icon isSelected={isSelected}/>
                                <ListItemText primary={route.title}
                                              style={{
                                                  marginLeft: "12px",
                                                  color: isSelected ? "#1890FF" : "rgba(0,0,0,0.85)"
                                              }} />
                            </ListItemButton>
                        )
                    })
                }
            </List>
        </Toolbar>
    )
});

export default Menu;
