import UserStore from "./UserStore";
import AppStore from "./AppStore";
import ToastStore from "./ToastStore";

/**
 * Хранилище для объединения остальных хранилищ
 */
export default class RootStore {
    userStore;
    appStore;
    toastStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.appStore = new AppStore();
        this.toastStore = new ToastStore();
    }
}
