import {observer} from "mobx-react";
import React from "react";
import Box from '@mui/material/Box';
import {VERSION} from "../../config";

/**
 * Компонента вывода версии
 */
const Footer = observer(() => {

    return (
        <Box sx={{
            height: "30px",
            padding: '0 20px 0 0',
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: "#676767",
            fontSize: "11px",
            backgroundColor: "#fff",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%"
        }}>
            Версия {VERSION}
        </Box>
    )
});

export default Footer;
