import React, {useRef} from "react";
import "./style.scss";
import Modal from "../../Elements/Modal";
import TextField from "@mui/material/TextField";
import Data from "./data";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useRootStore} from "../../../RootStoreProvider";

/**
 * Модальное окно подробностей организации
 */
const DetailsOrganizationModal = observer(({onClose, organization}) => {
    const store = useRootStore();
    const data = useRef(new Data(organization, store.toastStore, onClose));

    return (
        <Modal onClose={onClose}
               style={{width: 950}}
               title={"Карточка организации"}>
            <Box style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column"
            }}>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "587px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Наименование организации</p>
                        <TextField
                            size="small"
                            disabled
                            value={data.current.organization.name}
                            onChange={e => data.current.changeOrganization("name}", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Тип</p>
                        <FormControl sx={{width: "284px"}}
                                     size="small">
                            <Select
                                autoWidth={true}
                                disabled
                                value={data.current.organization.type}
                                onChange={e => data.current.changeOrganization("type", e.target.value)}>
                                <MenuItem value={"ip"}>ИП</MenuItem>
                                <MenuItem value={"org"}>Организация</MenuItem>
                                <MenuItem value={"gov"}>Государственный орган</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "587px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Юридический адрес</p>
                        <TextField
                            size="small"
                            disabled
                            value={data.current.organization.legalAddress}
                            onChange={e => data.current.changeOrganization("legalAddress", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">ИНН</p>
                        <TextField
                            size="small"
                            disabled
                            value={data.current.organization.inn}
                            onChange={e => data.current.changeOrganization("inn", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">ФИО владельца</p>
                        <TextField
                            size="small"
                            disabled
                            value={data.current.organization.ownerName}
                            onChange={e => data.current.changeOrganization("ownerName", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    marginTop: "28px",
                }}>
                    <Button variant="contained"
                            onClick={onClose}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "260px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}>
                        ОК
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
});

export default DetailsOrganizationModal;
