import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {useRootStore} from "../../RootStoreProvider";
import "./style.scss"
import TableNew from "../Elements/Table";
import Data from "./data";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DetailsRequestModal from "./DetailsRequestModal";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import FilterRequestsModal from "./FilterRequestsModal";

/**
 * Страница списка запросов на слияние
 */
const RequestsPage = observer(() => {
    const store = useRootStore();
    const [editItem, setEditItem] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const data = useRef(new Data(store.toastStore, store.userStore));

    useEffect(() => {
        store.appStore.setPageTitle("Слияние аккаунтов");
        data.current.getRequestList();
    }, []);

    return (
        <Box className="wrapper">
            <Box style={{
                display: "flex",
                alignItems:"center",
                justifyContent: "space-between",
                width: "100%",
                height: "64px"
            }}>
                <p style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.85)",
                    marginLeft: "8px"
                }}>
                    Слияние аккаунтов
                </p>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "514px"
                }}>
                    <Button variant="contained"
                            style={{marginRight: "10px", padding: "8px 30px"}}
                            onClick={() => setShowFilterModal(true)}
                            startIcon={<SearchIcon />}>
                        Поиск
                    </Button>
                </Box>
            </Box>
            <TableNew columns={data.current.columns}
                      rows={data.current.requests}
                      rowsPerPage={data.current.rowsPerPage}
                      onRowsPerPageChange={p => data.current.onRowsPerPageChange(p.target.value)}
                      activePage={data.current.activePage}
                      total={data.current.total}
                      onRequestSort={data.current.onChangeSortingType}
                      orderField={data.current.orderField}
                      orderBy={data.current.orderBy}
                      onRowClick={item => setEditItem(item)}
                      onPageChange={data.current.onPageChange}/>
            {
                showFilterModal &&
                <FilterRequestsModal onClose={() => setShowFilterModal(false)}
                                     filters={data.current.filters}
                                     onAccept={data.current.changeFilters}/>
            }
            {
                editItem &&
                <DetailsRequestModal onClose={() => setEditItem(null)}
                                     updateList={data.current.getRequestList}
                                     request={editItem}/>
            }
            {
                data.current.loading &&
                <div className="loader">
                    <CircularProgress />
                </div>
            }
        </Box>
    )
});

export default RequestsPage;
