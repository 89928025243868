export const USER_STATE = {
    ACTIVE: "active",
    BLOCKED: "blocked",
    COMPROMISED: "compromised",
    DELETED: "deleted",
    MERGED: "merged"
};

export const REQUEST_STATE = {
    CREATED: "CREATED",
    MERGED: "MERGED",
    CLOSED: "CLOSED",
    DENIED: "DENIED",
    UNMERGED: "UNMERGED"
};
