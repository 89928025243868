import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import Modal from "../../Elements/Modal";
import TextField from "@mui/material/TextField";
import Data from "./data";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import {useRootStore} from "../../../RootStoreProvider";
import InfoPortalModal from "../../InfoPortalModal";
import {USER_STATE} from "../../../consts";

/**
 * Моддалка подробностей о пользователе
 */
const DetailsUserModal = observer(({onClose, updateList, user}) => {
    const store = useRootStore();
    const [deleteItem, setDeleteItem] = useState(null);
    const data = useRef(new Data(user, store.toastStore, store.userStore, onClose, updateList));

    useEffect(() => {
        data.current.loadUser();
    }, []);

    const getStateLabel = () => {
        switch(data.current.user.state) {
            case USER_STATE.ACTIVE:
                return "Активный";
            case USER_STATE.BLOCKED:
                return "Заблокированный";
            case USER_STATE.COMPROMISED:
                return "Скомпрометированный";
            case USER_STATE.DELETED:
                return "Удаленный";
            case USER_STATE.MERGED:
                return "Объединен с другим аккаунтом";
            default:
                return "Неизвестный статус";
        }
    };

    const getStateColor = () => {
        switch(data.current.user.state) {
            case USER_STATE.ACTIVE:
            case USER_STATE.MERGED:
                return "rgba(70, 207, 75, 1)";
            case USER_STATE.BLOCKED:
            case USER_STATE.COMPROMISED:
            case USER_STATE.DELETED:
            default:
                return "rgba(255, 77, 79, 1)";
        }
    };

    const onClickUnbind = account => {
        data.current.unbindRequest(account.userAccountMergeRequestId);
    };

    return (
        <Modal onClose={onClose}
               style={{width: 950}}
               title={"Карточка пользователя"}>
            <Box style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                paddingTop: "30px",
                position: "relative"
            }}>
                <p className="user-status"
                   style={{color: getStateColor()}}>
                    {getStateLabel()}
                </p>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Фамилия</p>
                        <TextField
                            size="small"
                            disabled={data.current.disabledForm}
                            value={data.current.user.surname}
                            onChange={e => data.current.changeUser("surname", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label">Имя</p>
                        <TextField
                            size="small"
                            disabled={data.current.disabledForm}
                            value={data.current.user.name}
                            onChange={e => data.current.changeUser("name", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">Отчество</p>
                        <TextField
                            size="small"
                            disabled={data.current.disabledForm}
                            value={data.current.user.patronymic}
                            onChange={e => data.current.changeUser("patronymic", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <p className="label">E-mail</p>
                            <p className={`label-status ${!data.current.user.emailAddressCandidate && data.current.user.emailAddress && "active"}`}>
                                <span/>
                                {
                                    !data.current.user.emailAddressCandidate ?
                                        data.current.user.emailAddress ?
                                            "Подтвержден"
                                        :
                                            "Отсутствует"
                                    :
                                        "Не подтвержден"
                                }
                            </p>
                        </Box>
                        <TextField
                            size="small"
                            disabled={data.current.disabledForm}
                            value={data.current.user.emailAddress || data.current.user.emailAddressCandidate}
                            onChange={e => data.current.changeUser("emailAddress", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <p className="label">Телефон</p>
                            <p className={`label-status ${!data.current.user.msisdnCandidate && data.current.user.msisdn && "active"}`}>
                                <span/>
                                {
                                    !data.current.user.msisdnCandidate ?
                                        data.current.user.msisdn ?
                                            "Подтвержден"
                                            :
                                            "Отсутствует"
                                        :
                                        "Не подтвержден"
                                }
                            </p>
                        </Box>
                        <TextField
                            size="small"
                            disabled={data.current.disabledForm}
                            value={data.current.userPhone}
                            placeholder={"+79998887766"}
                            onChange={e => data.current.changeUser("msisdn", e.target.value)}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        <p className="label">ЕСИА ID</p>
                        <TextField
                            size="small"
                            value={data.current.user.esiaId}
                            onChange={() => {}}
                            disabled={true}
                            style={{width: "100%"}}
                            variant="outlined"/>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label-gray">Логин</p>
                        <p className="value-text">{data.current.user.login}</p>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label-gray">Пароль</p>
                        <p className="value-text">{data.current.user.password}</p>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                    <Button variant="contained"
                            disabled={data.current.disabledForm}
                            onClick={data.current.changePassword}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "284px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}>
                        Сбросить пароль
                    </Button>
                    </Box>
                </Box>
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: "24px",
                }}>
                    <Box style={{
                        width: "284px",
                        minWidth: "280px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label-gray">Класс доверия с учётной записи</p>
                        <div className="status-wrapper"
                             style={{backgroundColor: data.current.user.esiaTrusted ?
                                     "rgba(70, 207, 75, 0.1)" :
                                     "rgba(255, 77, 79, 0.1)"}}>
                            {
                                data.current.user.esiaTrusted ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <circle cx="9.49998" cy="9.99998" r="7.49998" fill="#46CF4B"/>
                                        <path d="M7.15577 10L8.89819 11.7424C8.97141 11.8156 9.09013 11.8156 9.16335 11.7424L12.7808 8.125" stroke="white" strokeWidth="1.5"/>
                                    </svg>
                                :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <circle cx="9.5" cy="10" r="7.5" fill="#FF4D4F"/>
                                        <path d="M6.1665 10H12.8332" stroke="white" strokeWidth="1.33333"/>
                                    </svg>
                            }
                            {data.current.user.esiaTrusted ? "Подтвержденная" : "Не подтвержденная"}
                        </div>
                    </Box>
                    <Box style={{
                        width: "284px",
                        minWidth: "284px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginRight: "20px",
                    }}>
                        <p className="label-gray">Связь с учётной записью ЕСИА</p>
                        <div className="status-wrapper"
                             style={{backgroundColor: data.current.user.esiaTrusted ?
                                     "rgba(70, 207, 75, 0.1)" :
                                     "rgba(255, 77, 79, 0.1)"}}>
                            {
                                data.current.user.esiaTrusted ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <circle cx="9.49998" cy="9.99998" r="7.49998" fill="#46CF4B"/>
                                        <path d="M7.15577 10L8.89819 11.7424C8.97141 11.8156 9.09013 11.8156 9.16335 11.7424L12.7808 8.125" stroke="white" strokeWidth="1.5"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <circle cx="9.5" cy="10" r="7.5" fill="#FF4D4F"/>
                                        <path d="M6.1665 10H12.8332" stroke="white" strokeWidth="1.33333"/>
                                    </svg>
                            }
                            {data.current.user.esiaTrusted ? "Настроена" : "Не настроена"}
                        </div>
                    </Box>
                    <Box style={{
                        width: "284px",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}>
                        {
                            data.current.user.state === USER_STATE.BLOCKED ?
                                <Button variant="contained"
                                        onClick={() => data.current.changeStateUser(USER_STATE.ACTIVE)}
                                        style={{
                                            color: "white",
                                            backgroundColor: "#1890FF",
                                            width: "284px",
                                            borderRadius: 2,
                                            textTransform: "none",
                                            fontSize: "16px"
                                        }}>
                                    Разблокировать
                                </Button>
                            :
                                <Button variant="contained"
                                        disabled={data.current.disabledForm}
                                        onClick={() => data.current.changeStateUser(USER_STATE.BLOCKED)}
                                        style={{
                                            color: "white",
                                            backgroundColor: "#1890FF",
                                            width: "284px",
                                            borderRadius: 2,
                                            textTransform: "none",
                                            fontSize: "16px"
                                        }}>
                                    Заблокировать
                                </Button>
                        }
                    </Box>
                </Box>
                {
                    data.current.userAccountsMerged.length > 0 &&
                    <Box style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        marginBottom: "0",
                    }}>
                        <Box style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                        }}>
                            <p className="label-gray">Присоединённые аккаунты</p>
                            <Box style={{
                                overflowY: "auto",
                                maxHeight: "350px",
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                marginBottom: "0",
                                marginRight: "-10px"
                            }}>
                                {
                                    data.current.userAccountsMerged.map((item, i) => {
                                        const fio = `${item.name} ${item.surname} ${item.patronymic || ""}`;
                                        return (
                                            <div className="account-wrapper"
                                                 key={i}>
                                                <div className="acc-row">
                                                    <div className="label">ФИО:</div>
                                                    <div className="value">{fio}</div>
                                                </div>
                                                <div className="acc-row">
                                                    <div className="label">Источник:</div>
                                                    <div className="value">{item.origin || "-"}</div>
                                                </div>
                                                <div className="acc-row">
                                                    <div className="label">Логин:</div>
                                                    <div className="value">{item.login || "-"}</div>
                                                </div>
                                                <div className="acc-row">
                                                    <div className="label">Почта:</div>
                                                    <div className="value">{item.emailAddress || "-"}</div>
                                                </div>
                                                <div className="acc-row">
                                                    <div className="label">Телефон:</div>
                                                    <div className="value">{item.msisdn || "-"}</div>
                                                </div>
                                                <div className="unbind-acc"
                                                     onClick={() => onClickUnbind(item)}>
                                                    Открепить аккаунт
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </Box>
                }
                <Box style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    marginTop: "28px",
                }}>
                    <Button variant="outlined"
                            onClick={onClose}
                            style={{
                                color: "black",
                                width: "230px",
                                borderRadius: 2,
                                textTransform: "none",
                                marginRight: "20px",
                                fontSize: "16px"
                            }}>
                        Отмена
                    </Button>
                    <Button variant="contained"
                            onClick={() => setDeleteItem(true)}
                            disabled={data.current.disabledForm}
                            style={{
                                color: "white",
                                backgroundColor: "#FF4D4F",
                                width: "240px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px",
                                marginRight: "20px"
                            }}>
                        Удалить пользователя
                    </Button>
                    <Button variant="contained"
                            onClick={data.current.save}
                            disabled={data.current.disabledForm}
                            style={{
                                color: "white",
                                backgroundColor: "#1890FF",
                                width: "260px",
                                borderRadius: 2,
                                textTransform: "none",
                                fontSize: "16px"
                            }}>
                        Сохранить изменения
                    </Button>
                </Box>
                {
                    deleteItem &&
                    <InfoPortalModal onClose={() => setDeleteItem(null)}
                                     onAccept={() => {
                                         data.current.delete();
                                         setDeleteItem(null);
                                     }}
                                     mainText={`Вы действительно хотите удалить пользователя "${data.current.user.surname} ${data.current.user.name} ${data.current.user.patronymic}"?`}/>
                }
            </Box>
        </Modal>
    )
});

export default DetailsUserModal;
