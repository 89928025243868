import React from "react";

const UserIcon = ({isSelected}) => {
    let fill = "black", fillOpacity = 0.85;
    if (isSelected) {
        fill = "#1890FF";
        fillOpacity = 1;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
            <path d="M11.4136 10.9294C11.1189 10.2312 10.6911 9.59702 10.1542 9.06217C9.61898 8.52578 8.98491 8.09812 8.28705 7.8028C8.2808 7.79967 8.27455 7.79811 8.2683 7.79498C9.24174 7.09186 9.87455 5.94655 9.87455 4.65436C9.87455 2.51373 8.14018 0.779358 5.99955 0.779358C3.85893 0.779358 2.12455 2.51373 2.12455 4.65436C2.12455 5.94655 2.75736 7.09186 3.7308 7.79655C3.72455 7.79967 3.7183 7.80123 3.71205 7.80436C3.01205 8.09967 2.38393 8.52311 1.84486 9.06373C1.30847 9.599 0.880808 10.2331 0.585488 10.9309C0.295367 11.6141 0.138898 12.3466 0.124551 13.0887C0.124134 13.1054 0.127059 13.122 0.133154 13.1375C0.139249 13.1531 0.14839 13.1672 0.160039 13.1792C0.171688 13.1911 0.185609 13.2006 0.200982 13.2071C0.216355 13.2135 0.232869 13.2169 0.249551 13.2169H1.18705C1.2558 13.2169 1.31049 13.1622 1.31205 13.095C1.3433 11.8887 1.82768 10.759 2.68393 9.9028C3.56986 9.01686 4.74643 8.52936 5.99955 8.52936C7.25268 8.52936 8.42924 9.01686 9.31518 9.9028C10.1714 10.759 10.6558 11.8887 10.6871 13.095C10.6886 13.1637 10.7433 13.2169 10.8121 13.2169H11.7496C11.7662 13.2169 11.7827 13.2135 11.7981 13.2071C11.8135 13.2006 11.8274 13.1911 11.8391 13.1792C11.8507 13.1672 11.8599 13.1531 11.8659 13.1375C11.872 13.122 11.875 13.1054 11.8746 13.0887C11.8589 12.3419 11.7042 11.6153 11.4136 10.9294ZM5.99955 7.34186C5.28236 7.34186 4.60736 7.06217 4.09955 6.55436C3.59174 6.04655 3.31205 5.37155 3.31205 4.65436C3.31205 3.93717 3.59174 3.26217 4.09955 2.75436C4.60736 2.24655 5.28236 1.96686 5.99955 1.96686C6.71674 1.96686 7.39174 2.24655 7.89955 2.75436C8.40736 3.26217 8.68705 3.93717 8.68705 4.65436C8.68705 5.37155 8.40736 6.04655 7.89955 6.55436C7.39174 7.06217 6.71674 7.34186 5.99955 7.34186Z" fill={fill} fillOpacity={fillOpacity}/>
        </svg>
    )
};

export default UserIcon;
